<template>
  <div class="authList">
    <div class="mation_content">
      <Wd streamer="授权应用"></Wd>
      <div class="mation_right fl">
        <p class="show_q">
          <span class="qy_s">
            <input
              type="text"
              v-model="data.appKey"
              id="app_key"
              placeholder="请输入企业授权码"
            />
            <button id="s_suo" @click="searchList">搜索</button>
          </span>
        </p>

        <div class="qy_sq">
          <div class="sq_a">
            <span>企业名称</span>
            <span>应用名称</span>
            <span>操作</span>
          </div>

          <div class="sq_b">
            <span>{{data.applyAuth.userName}}</span>
            <span>{{data.applyAuth.appName}}</span>
            <span class="cur" style="color:burlywood" @click="toAuthorize(data.applyAuth.appKey)" v-if="data.applyAuth.appType == 0">去授权</span>
            <span class="cur" style="color:burlywood" v-if="data.applyAuth.appType == 1">已授权</span>
          </div>

          <div class="sq_b" v-if="!data.applyAuth.appKey">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>{{data.prompt}}</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Wd from "@/components/banner.vue";
import { reactive,inject } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage,ElMessageBox } from "element-plus";
export default {
  name: "AuthList",
  components: {
    Wd,
  },
  setup() {
    const router = useRouter();
    const useAxios = inject("useAxios");
    const data = reactive({
      appKey: "",
      applyAuth:{},
      prompt:'您好！请输入企业授权码，进行搜索'
    });

    //搜索列表
    function searchList (){
      useAxios.get(`/foreground/web/company/unauth/list?appKey=${data.appKey}`).then(res=>{
        if(res.data.code){
          if(res.data.attach){
            data.applyAuth = res.data.attach;
          }else{
            data.applyAuth = {};
            data.prompt = '您好！没有该企业授权码，请重新输入';
          }
        };
      })
    };

    //去授权
    function toAuthorize(appKey){
      ElMessageBox.confirm('你确定要授权该应用吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        useAxios.post('/foreground/web/company/unauth/add',{
          appKey:appKey,
        }).then(res=>{
          if(res.data.code == 0){
            ElMessage.success('授权成功');
            setTimeout(()=>{
              router.replace('/userCenter/myAuth');
            },1500);
          };
        });
      })
    }

    return {
      data,
      searchList,
      toAuthorize
    };
  },
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto !important;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px !important;
  padding-bottom: 10px;
  .mation_right {
    width: 100%;
    height: auto;
    clear: both;
    .show_q {
      color: #0078ff;
      font-size: 18px;
      height: 66px;
      padding-left: 20px;
      border-bottom: 2px solid #0078ff;
      margin-bottom: 0px;
      font-weight: bold;
      box-sizing: border-box;
      .qy_s {
        float: right;
        font-size: 16px;
        #app_key {
          height: 40px;
          width: 300px;
          border: 1px solid #e3e3e3;
          padding-left: 20px;
          color: #c2c2c2;
          box-sizing: border-box;
        }
        #s_suo {
          width: 100px;
          height: 40px;
          color: #fff;
          vertical-align: middle;
          line-height: 40px;
          background: #0078ff;
          border: 0px;
          margin-left: -5px;
          margin-top: -2px;
          cursor: pointer;
          outline: none;
        }
      }
    }
    .qy_sq {
      width: 100%;
      line-height: 40px;
      font-size: 16px;
      clear: both;
      .sq_a {
        height: 50px;
        line-height: 50px;
        background: #f4f4f4;
        box-sizing: border-box;
      }
      .sq_a, .sq_b {
        width: 100%;
        border-bottom: 1px solid #e3e3e3;
        color: #000;
        box-sizing: border-box;
      }
    }   
  }
}
@media screen and (max-width: 1280px) {
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .mation_content {
    width: 90%;
  }
}
.sq_a span, .sq_b span {
    display: inline-block;
    width: 33%;
    float: left;
    text-align: center;
    box-sizing: border-box;
}
.cur{
  cursor: pointer;
}
</style>
