<template>
    <div class="authed">
        <div class="content">
            <div class="card clearfix">
                <div class="leftImg"></div>
                <div class="rightCont">
                    <div class="itemcol">
                        <label>企业名称：</label>
                        <span>{{dataList.userName}}</span>
                    </div>
                    <div class="itemcol">
                        <label>账号：</label>
                        <span>{{dataList.account}}</span>
                    </div>
                    <div class="itemcol">
                        <label>注册时间：</label>
                        <span>{{dataList.registTimeName}}</span>
                    </div>
                    <div class="itemcol">
                        <label>认证状态：</label>
                        <span>{{dataList.authStatusName}}</span>
                    </div>
                    <div class="itemcol">
                        <label>服务类型：</label>
                        <span>{{dataList.userTypeName}}</span>
                    </div>
                </div>
            </div>

            <div class="card clearfix">
                <div class="rightCard">
                    <div class="leftImg2"></div>
                    <div class="rightCont">
                        <div class="itemcol">
                            <label>认证信息</label>
                        </div>
                        <div class="itemcol">
                            <label>证件类型：</label>
                            <span>企业营业执照</span>
                        </div>
                        <div class="itemcol">
                            <label>证件号码：</label>
                            <span>{{dataList.tyshxydmNo}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-button type="primary" @click="seeDetail" style="width: 20%; margin-top: 30px">查看</el-button>
    </div>
</template>

<script>
import { inject, onBeforeMount, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'authed',
    setup(props){
        const router = useRouter();
        const useAxios = inject('useAxios');

        const data = reactive({
            dataList: {}
        })

        onBeforeMount(()=>{
            getCompanyInfo();
        });

        // 已认证公司信息
        const getCompanyInfo = () => {
            useAxios.get("/foreground/web/company/auth/detail").then((res) => {
                if(res.data.code == "0"){
                    data.dataList = res.data.attach;
                }
            }).catch((err) => {});
        }

        const seeDetail = ()=>{
            router.push({
                name: 'ViewBasicInfo',
            });
        }

        return {
            ...toRefs(data),
            seeDetail
        }
    }
}
</script>

<style lang="less" scoped>
.authed{
    width: 100%;
    .content{
        display: flex;
        .card{
            width: 50%;
            padding: 60px 0px;
            .leftImg{
                width: 137px;
                height: 165px;
                background: url('../assets/img/info_left.png');
                float: left;
            }
            .leftImg2{
                width: 137px;
                height: 165px;
                background: url('../assets/img/info_right.png');
                float: left;
            }
            .rightCont{
                float: left;
                margin-left: 30px;
                .itemcol{
                    color: #000;
                    line-height: 34px;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
                }
            }
            .rightCard{
                float: right;
            }
        }
    }
}
</style>
