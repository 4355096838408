<template>
  <div>
    <LoginTem :showSkip="true">
      <div class="user-reg">用户注册</div>
      <div class="group-box">
        <input v-model="info.account" placeholder="邮箱" />
      </div>
      <div class="group-box verify">
        <input v-model="info.verify" placeholder="邮箱验证码"/>
        <el-button type="info" @click="getVerify" :disabled="info.verifyDisabled">{{ getVercode }}</el-button>
      </div>
      <div class="group-box">
        <input v-model="info.pass" placeholder="密码" maxlength="255" type="password"/>
      </div>
      <div class="group-box">
        <input v-model="info.passConfirm" placeholder="确认密码" maxlength="255" type="password"/>
      </div>
      <p class="choose">
        <input
          type="checkbox"
          v-model="info.category"
          value="service"
        /><span @click="open(1)">同意《诺曼服务协议》</span>
      </p>
      <p class="choose">
        <input
          type="checkbox"
          v-model="info.privacy"
          value="auto"
        /><span @click="open(2)">认可使用诺曼电子签约云平台作为签订协议（包括但不限于合同）的方式</span>
      </p>
      <el-button type="primary" class="margin-30" @click="signIn" :disabled="info.bnDisabled">
        提交注册
      </el-button>
    </LoginTem>
  </div>
</template>
<script>
import LoginTem from "@/components/login-template.vue";
import { inject, reactive, ref } from "vue";
import { ElMessageBox,ElMessage  } from "element-plus";
import { useRouter } from 'vue-router';

export default {
  name: "Register",
  components: {
    LoginTem,
  },
  setup() {
    const useAxios = inject("useAxios");
    const getVercode = ref("获取验证码");
    const router = useRouter();
    const info = reactive({
      account: "",
      verify: "",
      verifyDisabled:false,
      pass: "",
      passConfirm: "",
      category: [],
      privacy:[],
      proContent: "",
      bnDisabled:false
    });
    //提交注册
    function signIn() {
      if(!verifyInfo()){
        return;
      };
      info.bnDisabled = true;
      let obj = {
        account: info.account,
        code: info.verify,
        confirmPassword: info.passConfirm,
        password: info.pass,
      };
      useAxios.post("/foreground/web/register", obj).then((res) => {
          if (res.data.code == 0) {
            info.bnDisabled = false;
            ElMessage.success({
              message: "恭喜你，注册成功！",
              type: "success",
            });
            setTimeout(() => {
              router.replace({
                path:'/login'
              });
            }, 1500);
          }else{
            info.bnDisabled = false;
          };
        }).catch((err) => {
          info.bnDisabled = false;
        });
    };
    //查看协议
    function open(type) {
      useAxios
        .post("/foreground/web/company/agreement/content", { agreementType: type })
        .then((res) => {
          if (res.data.code == 0) {
            info.proContent = res.data.attach.content;
            ElMessageBox.alert(info.proContent, "诺曼用户注册与使用协议", {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              customClass: "agreement-content",
              lockScroll: false,
              closeOnClickModal: true,
              closeOnPressEscape: true,
            });
          }
        })
        .catch((err) => {});
    };
    // 获取验证码
    function getVerify() {
      const rePass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
      const isPass = rePass.test(info.account);
      if(info.account == ''){
        ElMessage.error('请填写邮箱');
        return;
      }else if(!isPass){
        ElMessage.error('邮箱格式有误，请重新填写');
        return;
      };
      info.verifyDisabled = true;
      useAxios.post("/foreground/web/email/code", {
          email: info.account,
          type: 2,
        }).then((res) => {
          if (res.data.code == 0) {
            getVercode.value = 60;
            let timing = setInterval(() => {
              getVercode.value --;
              if(getVercode.value == 0){
                info.verifyDisabled = false;
                getVercode.value = '获取验证码'
                clearInterval(timing);
              }
            }, 1000);
          }else{
            info.verifyDisabled = false;
          }
        })
        .catch((err) => {});
    }
    //信息校验
    function verifyInfo (){
      const rePass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
      const isPass = rePass.test(info.account);
      if(info.account == ''){
        ElMessage.error('请填写邮箱');
        return false;
      }else if(!isPass){
        ElMessage.error('邮箱格式有误，请重新填写');
        return false;
      }else if(info.pass == ''){
        ElMessage.error('请输入密码');
        return false
      }else if(info.passConfirm == ''){
        ElMessage.error('请输入确认密码');
        return false
      }else if(info.category.length == 0){
        ElMessage.error('请勾选诺曼服务协议');
        return false
      }else if(info.privacy.length == 0){
        ElMessage.error('请勾选诺曼隐私协议');
        return false
      }else if(info.pass !== info.passConfirm){
        ElMessage.error('两次密码输入不一致，请重新输入');
        return false
      }
      return true
    };
    return {
      getVercode,
      info,
      signIn,
      open,
      getVerify,
    };
  },
  methods: {},
};
</script>
<style scoped lang="less">
.user-reg {
  background: url(../assets/img/hm.png) no-repeat bottom center;
  color: #0577fe;
  font-weight: 600;
  padding: 0 20px 10px;
}
.group-box {
  background: url(../assets/img/cc.png) no-repeat bottom;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  input {
    width: 95%;
    line-height: 50px;
    height: 50px;
    color: #000;
    background: transparent;
    font-size: 15px;
    padding-left: 5%;
    border: none;
    border-radius: 4px;
    border: 0;
    outline: none;
  }
}
.verify {
  display: flex;
  img {
    width: 130px;
    height: 45px;
    margin-left: 20px;
  }
  .el-button {
    padding: 2px 20px;
    background-color: #000;
    border-color: #000;
    height: 40px;
  }
}
.choose {
  margin-top: 10px;
  text-align: left;
  color: #e0e0e0;
  text-decoration: underline;
  padding: 0 15px;
  cursor: pointer;
  input[type="checkbox"] {
    margin-right: 10px;
  }
}
.el-button {
  padding: 12px 60px;
  background-color: #0577fe;
  border-color: #0577fe;
}
.margin-30 {
  margin-top: 30px;
}
</style>
