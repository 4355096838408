<template>
  <div class="auth-protocol">
    <div class="protocol-subject">
      您正在进行诺曼签约授权给第三方的操作，请仔细阅读相关说明
    </div>
    <div class="rich-text" v-html="richContent"></div>
    <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
    <el-button type="primary" @click="toAuth">确认</el-button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  name: "AuthProtocol",
  setup() {
    const router = useRouter();
    const richContent = ref(`<p>
	<p class="MsoNormal">
		尊敬的用户：
	</p>
	<p class="MsoNormal">
		您即将通过本登录页面使用诺曼账号登录第三方站点或软件，如果您不接受下列条款，请您停止进行登录操作：
	</p>
	<p class="MsoNormal">
		第一条 您完成登录后，即视为您知悉并同意授权第三方站点和软件使用诺曼数据接口调用您诺曼账号和与该账号关联的系统的相关信息数据。
	</p>
	<p class="MsoNormal">
		第二条 第三方站点和软件的质量和品质由第三方独立负责。如因第三方站点或软件存在漏洞、瑕疵、故障、病毒等原因造成您相关权益受损的，您可以请诺曼协调，但您不应就登录和使用第三方站点或软件的后果要求诺曼承担任何责任。
	</p>
	<p class="MsoNormal">
		第三条 为了保障您的权益，诺曼将对第三方站点和软件进行定期检测和代码扫描，您理解任何技术手段并非完全可靠。诺曼郑重提醒您启用杀毒和安全措施，以减轻病毒、恶意代码、漏洞等的不良影响。
	</p>
	<p class="MsoNormal">
		第四条 网络欺诈往往会模仿、仿冒本登录页面的样式制作视觉相似的页面引诱用户输入账号和密码等信息，诺曼提醒您防范网络风险，不要向非诺曼的站点或任何人士透露诺曼账号、密码等相关信息。
	</p>
	<p class="MsoNormal">
		第五条 如您发现第三方站点或软件侵犯您的合法权益的，或您不幸遭遇网络欺诈的，请您及时联系并通知诺曼。
	</p>
	<p class="MsoNormal">
		&nbsp;
	</p>
  </p>
  <p>
  </p>`);
    const checked = ref(false);
    const showToast = ref(true);
    function toAuth() {
      if (!checked.value) {
        open4();
        return;
      }
      router.push("/userCenter/authList");
    }
    function open4() {
      if (!showToast.value) {
        return;
      }
      showToast.value = false;
      ElMessage.warning({
        message: "您还未勾选同意该协议",
        type: "warning",
        onClose: () => {
          showToast.value = true;
        },
      });
    }
    return {
      richContent,
      checked,
      toAuth,
    };
  },
};
</script>

<style scoped lang="less">
.auth-protocol {
  text-align: left;
  width: 1200px;
  margin: auto;
  .protocol-subject {
    color: #000;
    font-size: 18px;
    margin-top: 30px;
  }
  .rich-text {
    width: 100%;
    height: 425px;
    margin-top: 10px;
    background: #fff;
    overflow: auto;
    padding: 10px 20px;
  }
  .el-checkbox {
    margin-top: 20px;
  }
  .el-button {
    display: block;
    margin-top: 20px;
    width: 100px;
  }
}
</style>