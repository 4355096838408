<template>
  <div class="advancedApp">
    <div class="mation_content">
      <div class="topCol">
        <arrowComp :streamer="apply.streamerMsg"></arrowComp>
      </div>
      <div class="mation_right fl">
        <p class="crea" style="font-family: 微软雅黑" @click="createApp()">去创建应用</p>
        <div class="tScroll">
          <table class="pp">
            <tbody>
              <tr class="mm">
                <td class="bb">
                  <p style="width: 103px">应用名称</p>
                </td>
                <td class="bb">
                  <p style="width: 100px">应用类型</p>
                </td>
                <td class="bb">
                  <p style="width: 250px">授权码(app_key)</p>
                </td>
                <td class="bb">
                  <p style="width: 250px">app_secrect</p>
                </td>
                <td class="bb">
                  <p style="width: 110px">审核情况</p>
                </td>
                <td class="bb">
                  <p style="width: 110px">状态</p>
                </td>
                <td class="bb">
                  <p class="border_right" style="width: 140px">操作</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="empty-list" v-if="apply.advancedList.length == 0">你还没有创建过应用</div>
                </td>
              </tr>
              <tr class="nn" v-for="(item,index) in apply.advancedList" :key="item.id">
                <td class="fl c_text">
                  <div class="nxd" style="width: 103px">{{item.appName}}</div>
                </td>
                <td class="fl c_text">
                  <div class="nxd" style="width: 100px" v-if="item.appType == 0">自用</div>
                  <div class="nxd" style="width: 100px" v-if="item.appType == 1">第三方</div>
                </td>

                <td class="fl c_text" v-if="!item.appKey || item.confirmStatus != 2">
                  <div class="nxd" style="width: 250px">
                    暂无授权码(app_key)
                  </div>
                </td>

                <td class="fl c_text" v-if="item.appKey && item.confirmStatus == 2">
                  <div class="nxd copy" style="width: 250px">
                    <input :value="item.appKey" readonly :class="'appKey'+ index"/>
                    <span @click="copyUrl2('appKey'+index)">点击复制</span>
                  </div>
                </td>

                <td class="fl c_text" v-if="!item.appSecrect || item.confirmStatus != 2">
                  <div class="nxd" style="width: 250px">暂无app_secrect</div>
                </td>

                <td class="fl c_text" v-if="item.appSecrect && item.confirmStatus == 2">
                  <div class="nxd copy" style="width: 250px">
                    <input :value="item.appSecrect" readonly :class="'appSecrect'+ index"/>
                    <span @click="copyUrl2('appSecrect'+ index)">点击复制</span>
                  </div>
                </td>



                <td class="fl c_text">
                  <div class="nxd" style="width: 110px">
                    <span style="color: #04c" v-if="item.confirmStatus == 0"> 未审核</span>
                    <span style="color: #49afcd" v-if="item.confirmStatus == 1"> 等待审核 </span>
                    <span style="color: #5bb75b" v-if="item.confirmStatus == 2">审核通过 </span>
                    <span style="color: #bd362f" v-if="item.confirmStatus == 3"> 拒绝</span>
                  </div>
                </td>

                <td class="fl c_text">
                  <div class="nxd" style="width: 110px">
                    <span style="color: #49afcd" v-if="item.appStatus == 0">未启用</span>
                    <span style="color: #5bb75b" v-if="item.appStatus == 1">已启用</span>
                  </div>
                </td>

                <td class="fl c_text">
                  <div class="nxd copy" style="width: 230px">
                    <span class="lk" @click="edit(item.id)" v-if="item.confirmStatus == 0 || item.confirmStatus == 1 || item.confirmStatus == 3">编辑</span>
                    <span class="lk" @click="examine(item.id)" v-if="item.confirmStatus == 2 ">查看</span>
                    <span class="lk" @click="del(item.id)">删除</span>
                    <span class="lk" @click="getAuthUser(item.appKey)" v-if="item.appType != 0  && item.confirmStatus!=1 && item.confirmStatus!=3">查看被授权用户</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Application from '@/components/application.vue';
import arrowComp from '../components/banner'
import { inject, reactive,  onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage,ElMessageBox } from "element-plus";
export default {
  name: "AdvancedApp",
  setup() {

    const router = useRouter();
    const useAxios = inject("useAxios");
    const apply = reactive({
      advancedList:[],
      streamerMsg :  "我的应用",
    });
    onMounted(()=>{
      getList();
    });
    function copyUrl2(ref){
      document.getElementsByClassName(ref)[0].select()
      document.execCommand("copy")
      ElMessage.success('复制成功');
    };
    //获取列表数据
    function getList(){
      useAxios.get('/foreground/web/company/app/list').then(res=>{
        if(res.data.code == 0){
          apply.advancedList = res.data.attach;
        };
      })
    };
    //编辑
    function edit(id){
      router.push(`/AppAdd/${id}`);
    }
    //查看
    function examine(id){
      router.push(`/examine/${id}`)
    }
    //删除数据
    function del(id){
      ElMessageBox.confirm('你确定要删除该应用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          useAxios.post(`/foreground/web/company/app/delete`,{id:id}).then(res=>{
            if(res.data.code == 0){
              ElMessage.success('删除成功');
              getList();
            }
          })
        })
    }

    function getAuthUser(appKey){
      // console.log(appKey)
      router.push(`/getAuthUser/${appKey}`)
    }

    //创建引用
    function createApp(){
      router.push('/applicationPro');
    }

    return {
      copyUrl2,
      createApp,
      apply,
      edit,
      del,
      examine,
      getAuthUser,
    };
  },
  components:{
    arrowComp,
  }
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px;
  padding-bottom: 10px;
}
.mation_right{
  width: 100%;
  height: auto;
  clear: both;
  .crea{
    color: #fff;
    font-size: 16px;
    padding: 5px 25px;
    float: right;
    background: #0078FF;
    border-radius: 20px;
    cursor:pointer;
  }
  .tScroll{
    width: 100%;
    clear: both;
    overflow-x: scroll;
    cursor: default;
    text-align: center;
    margin-top: 40px;
    padding-bottom: 15px;
    display: block;
    border: 2px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
    .pp{
      width: 100%;
      .mm{
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid #000;
        .bb{
          padding: 15px 0px;
          height: 52px;
          color: #0078FF;
          font-size: 15px;
          text-align: center;
          font-weight: bold;
          p{
            height: 20px;
            border-right: 2px #000 solid;
          }
        }
      }
      .nn{
        height: 60px;
        .c_text{
          line-height: 66px;
          height: 66px;
          font-size: 14px;
          display: block;
          font-family: "微软雅黑";
          color: #000;
          div{
            padding: 0px 5px;
            box-sizing: border-box;
          }
          span{
            color: #000;
            cursor: pointer;
          }
          .lk{
            margin-left: 20px;
          }
        }
        input{
          height: 32px;
          line-height: 32px;
          width: 160px;
          background: none !important;
          margin-right: 5px;
          font-size: inherit;
          border: none;
        }
      }
    }
  }
}

table{
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}
.border_right{
  border-right: none !important;
}
table,tbody,tr,td,p{
  box-sizing: border-box;
}
.copy span:hover{
 color: #0078FF !important;
}

::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 10px 0px;
}
::-webkit-scrollbar {
    height: 5px !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.empty-list{
  text-align: center;
  color:#000;
  font-weight: 900;
  margin-top: 15px;
}

@media screen and (max-width: 1280px){
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px){
  .mation_content {
      width: 90%;
  }
}
</style>
