<template>
  <div class="applicationP-pro">
    <Application streamer="全部文件"></Application>
    <div class="protocol-subject">
      以下是诺曼签约开放平台开发者服务协议，请仔细阅读相关说明
    </div>
    <div class="rich-text" v-html="richContent"></div>
    <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
    <el-button type="primary" @click="toAuth">确认</el-button>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import Application from "@/components/application.vue";
export default {
  name: "ApplicationPro",
  setup() {
    const router = useRouter();
    const richContent = ref(`<p>
	<p class="MsoNormal">
		诺曼开放平台开发者服务协议<span></span>
	</p>
	<p class="MsoNormal">
		【声明】<span></span>
	</p>
	<p class="MsoNormal">
		欢迎您使用诺曼开放平台提供的开发服务！<span></span>
	</p>
	<p class="MsoNormal">
		为使用诺曼开放平台开发服务（以下简称“本服务”），您应当阅读并遵守本协议
、《诺曼注册服务协议》及《诺曼电子签约服务协议》等诺曼平台相关协议、规则。<span></span>
	</p>
	<p class="MsoNormal">
		请您务必逐条审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款；以及开通或使用某项服务的单独协议条款。<span></span>
	</p>
	<p class="MsoNormal">
		除非您已阅读并接受本协议及相关协议、规则等全部条款，否则，您无权使用本公司提供的本服务。您使用本公司的本服务，即视为您已阅读并同意上述全部协议条款。一旦您违反本协议所提及全部协议条款，大连诺曼网络科技有限公司（以下简称“诺曼公司”或“本公司”）有权单方限制、中止或终止向您提供本服务，并有权追究您的相关责任。<span></span>
	</p>
	<p class="MsoNormal">
		第一条
名词解释<span></span>
	</p>
	<p class="MsoNormal">
		除特别说明，下列术语在本协议中的含义为：<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.1</span>开发者：指经有效注册、申请后，将以其自身名义开发、创作或获得合法授权运营且独立承担法律责任的各种应用接入诺曼开放平台而向用户提供各种服务的个人、法人或其他组织（以下简称“您”）。<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.2</span>应用：指由开发者以其自身名义开发、创作，享有合法的运营权利，并进行运营和独立承担法律责任的，通过在诺曼开放平台登记后使用诺曼开放平台服务的应用程序及相关服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.3</span>诺曼开放平台：指由本公司所拥有、控制、经营的【<span>https://qy.normanchain.com</span>】网站及前述各平台网站的下属子页面（以下简称“开放平台”、“平台”）。<span></span>
	</p>
	<p class="MsoNormal">
		本公司、开发者均知悉并同意：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）开放平台是一个中立的平台服务提供者，本协议项下仅向开发者提供开发服务开放接口或相关中立的技术支持服务，并且本公司有权基于诺曼平台有序运营、健康发展等因素考量，自主选择使用本服务的开发者。<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）开发者的应用由开发者以其自身名义开发或享有合法的运营权利，并独立承担全部责任。本公司无义务参与开发者应用的研发、运营等任何活动；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）因开发者应用及服务产生的任何纠纷，以及开发者违反相关法律法规或本协议约定所致后果，均由开发者独立承担责任、赔偿损失，与本公司无关。如侵害到本公司或他人权益的，开发者须承担全部责任和赔偿一切损失。<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.4</span>用户数据：是指用户在开放平台及应用中产生的与用户相关的数据，包括但不限于用户提交的语音数据、图像数据、用户操作行为形成的数据等。“用户数据”的所有权及其他相关权利属于本公司，且是本公司的商业秘密，依法属于用户享有的相关权利除外。<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.5</span>开放平台运营数据是指用户、开发者在使用开放平台服务中产生的相关数据，包括但不限于用户或开发者提交的数据、操作行为形成的数据及各类交易数据等。“开放平台运营数据”的所有权及其他相关权利属于本公司，且是本公司的商业秘密，依法属于用户享有的相关权利除外。<span></span>
	</p>
	<p class="MsoNormal">
		<span>1.6</span>诺曼或其服务平台：指本公司诺曼软件、诺曼公众平台、诺曼开放平台等与诺曼服务相关的网站、应用、软件等。<span></span>
	</p>
	<p class="MsoNormal">
		第二条
开发者的权利和义务<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1</span>帐号注册<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.1</span>您应当通过登录诺曼开放平台网站，或本公司其他指定途径，使用专属您的电子邮箱注册开发者帐号以成为开发者，开发者帐号一经注册成功，电子邮箱不得变更，且该帐号不可转让、赠与、继承等。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.2</span>您注册帐号时，应使用您拥有合法使用权的电子邮箱，并遵守相关协议、规则等的约束。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.3</span>为保障本服务的合法性及安全性，您不得违反本协议约定将您的帐号用于其他目的，包括但不限于使用您的帐号为第三方应用申请本服务等。否则，本公司有权随时单方限制、中止或终止向您及您所登记的应用提供本服务，且未经本公司同意，您及您所登记的应用不得再次使用本服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.4</span>您注册帐号使用的电子邮箱，是您登录及使用本服务的凭证。您应当做好保密措施，包括但不限于电子邮箱帐号、密码以及进入和管理本服务中的各类产品与服务的口令、密码等。因您保密措施不当或您的其他行为，致使上述信息丢失或泄漏，所致全部损失及相关责任，均由您自行承担。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.5</span>您保证，您合法拥有注册本服务帐号的电子邮箱。该电子邮箱在本服务中的操作行为，包括但不限于注册本服务帐号，提交相应资质材料，确认和同意相关协议和规则，选择具体服务类别，以及进行费用结算等，均视为您本人的行为，对您均有约束力，您承担全部法律责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.6</span>若您发现有他人冒用或盗用您的帐号及密码，或任何其他未经您合法授权的情形时，应立即以有效方式通知本公司。本公司收到您的有效请求并核实身份后，会根据不同情况采取相应措施。若您提供的信息不准确，导致本公司无法核实您的身份，或本公司无法判断您的需求等，而导致本公司无法及时处理，给您带来的损失，您应自行承担；本公司对您的请求采取措施需要合理期限，对于您通知本公司以及本公司根据您的有效通知采取措施之前，由于他人行为给您造成的损失，本公司不承担任何责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.1.7</span>您理解并同意，为保护您及用户的数据安全，防止用户信息泄露、毁损、篡改或者丢失，本公司有权对您接入的信息系统实行接入审查，包括但不限于技术水平审查、安全水平审查、主体资质审查等，并根据审查结果向您提出防入侵、防病毒等措施建议。若您的信息系统仍无法符合保护用户数据安全的要求，本公司有权拒绝或终止提供本服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.2</span>资质<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.2.1</span>您保证，您如实填写和提交帐号注册与开发者认证资料，并对资料的真实性、合法性、准确性和有效性承担责任；您具备使用本服务，接入和运营应用，或提供相关服务等行为的相关合法资质，或经过了相关政府部门的审核批准；您提供的主体资质材料、相关资质或证明以及其他任何文件等信息真实、准确、完整，信息发生变更后，您应当及时更新；您具备履行本协议项下义务的行为能力；您履行相关义务的行为不违反任何对您的有约束力的法律法规。如您违约，本公司有权拒绝或终止提供本服务，并有权向您追究违约责任，全部责任由您自负。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.2.2</span>您保证，按照本公司要求提交使用本服务所必须的真实、准确的主体资质材料（经您签章确认），以及联系人姓名（名称）、地址、电子邮箱等相关资料。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.2.3</span>您保证，您在开放平台上通过您的应用提供的各种服务，依法已经具有相关的合法资质或获得了有关部门的许可或批准，并会向本公司提交相关资质或证明文件（包括但不限于版权、专利权等）。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.2.4</span>您保证，您在诺曼开放平台上通过您的应用提供的各种服务合法合规，不会侵犯任何人的合法权益。 <span></span>
	</p>
	<p class="MsoNormal">
		<span>2.3</span>收费服务<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.3.1</span>诺曼开放平台部分服务是以收费方式提供的（包括但不限于诺曼开放平台开发者资质认证服务等），如您使用收费服务，请遵守相关协议。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.3.2</span>本公司有权单方决定对对收费服务的收费标准、方式进行变更，亦可单方决定对原免费服务开始收费。本公司将在相应服务页面进行通知或公告，而无需征得您的同意。若您存异议，则应停止使用该服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.4</span>开发者资质认证<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.4.1</span>您符合一定条件后，可以申请开发者资质认证（以下简称“开发者认证”）。开发者认证服务内容仅限于开发者资质审核，本公司仅对您提交的资料和信息进行审核，不对您实际运营行为承担任何责任或提供任何担保。因您的行为导致与其他用户或第三方发生争议的，由您独立对外承担责任，致本公司、其他用户或第三方损害，您应当承担赔偿责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.4.2</span>为向您提供更专业的服务，您同意授权本公司委托第三方对您所提交的认证资料进行审核，并根据审核情况独立判断，以确定认证结果。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5</span>应用要求<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.1</span>您应自行负责您应用的创作、开发、编辑、加工、修改、测试、运营及维护等工作，并且自行承担相应的费用。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.2 </span>您的应用，应符合相关法规、技术规范或标准，同时，还应符合开放平台的对接入应用在技术、安全等方面的统一要求，以确保应用可以在开放平台安全、稳定的运营。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.3 </span>您应用对本服务相关的<span>API</span>的使用，不得进行任何形式的更改。您应在应用中正确、完整地标注“<span>Powered by </span>诺曼开放平台”或“技术由诺曼开放平台提供”字样，否则本公司有权随时中止或终止向您提供本服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.4 </span>您的应用在开放平台上运营期间，您需向用户提供及时有效的客户服务，客户服务形式包括但不限于通过明确且合理的方式告知用户客户服务渠道、提供电话等，并自行承担客服费用。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.5 </span>您应当在应用中向相关权利人提供投诉途径，确保权利人在认为您侵犯其合法权益时可以直接向您主张权利。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.6 </span>您申请使用本服务的应用应为您自行开发、创作或获得合法授权运营并独立承担法律责任的应用，您不得通过您的帐号为任何第三方应用代为申请、使用本服务，否则，本公司有权随时单方限制、中止或终止向您及您所登记的应用提供本服务，且未经本公司同意您及您所登记的应用不得再次使用本服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.5.7 </span>为保障用户对应用进行正确的识别和区分，保障用户的知情权、选择权以及其他第三方的合法权益，您的应用名称应当与您的应用存在关联性，不得以任何形式侵犯他人合法权益，且每一个应用只能使用一个应用名称。您应当向本公司提交真实、准确、完整的应用或网站信息，包括但不限于域名、链接、在其他第三方分配的帐号、字段等，本公司有权对此进行提取、分析、评估。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6</span>应用运营<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6.1</span>您应自行合规运营您的应用，履行相关义务，并自行承担全部责任，包括但不限于：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）依照相关法律法规的规定，保存相应的访问、使用等日志记录；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）国家有权机关向您依法查询相关信息时，应积极配合提供；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）主动履行其他您依法应履行的义务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6.2</span>您保证：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）您的应用、提供给用户的相关服务及发布的相关信息、内容等，不违反相关法律、法规、政策等的规定及本协议或相关协议、规则等，也不会侵犯任何人的合法权益；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）您自行对您应用中由用户使用应用服务产生的内容（包括但不限于留言、消息、评论、名称等）负责，保证其不违反相关法律、法规、政策的规定以及公序良俗等。否则，您应及时采取删除、断开连接或其他有效措施；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）应用设计上应当重视用户体验，尊重用户知情权、选择权，应用服务应当坚持诚信原则，禁止误导、欺诈、混淆用户，尊重用户的隐私，不骚扰用户，不制造垃圾信息。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6.3</span>您保证，不从事以下违法行为，也不得为以下行为提供便利：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）反对宪法所确定的基本原则的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）损害国家荣誉和利益的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）煽动民族仇恨、民族歧视，破坏民族团结的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）破坏国家宗教政策，宣扬邪教和封建迷信的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）散布谣言，扰乱社会秩序，破坏社会稳定的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>7</span>）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>8</span>）侮辱或者诽谤他人，侵害他人合法权益的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>9</span>）侵害他人知识产权、商业秘密等合法权利的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>10</span>）恶意虚构事实、隐瞒真相以误导、欺骗他人的行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>11</span>）发布、传送、传播广告信息及垃圾信息；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>12</span>）其他法律法规禁止的行为。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6.4</span>您保证，不从事以下不适当行为，也不得为以下行为提供便利：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）删除、隐匿、改变开放平台显示或包含的任何专利、著作权、商标或其他所有权声明；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）以任何方式干扰或企图干扰本公司任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）未经本公司书面许可，在应用中使用包括但不限于“诺曼网络平台”、
“诺曼”或其他本公司商标标识或其任何变体、缩写、改写等；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）避开、尝试避开或声称能够避开任何内容保护机制，或导致用户认为其直接与诺曼开放平台及本公司相关产品进行交互；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）在未获得本公司书面许可的情况下，以任何方式使用或为第三方应用申请使用本公司<span>URL</span>地址、技术接口等；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）在未经过用户同意的情况下，向任何其他用户及第三方显示或以其他任何方式提供该用户的任何信息；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>7</span>）请求、收集或以其他方式获取用户登录帐号、密码或其他任何身份验证凭据；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>8</span>）为任何用户自动登录到诺曼开放平台提供代理身份验证凭据；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>9</span>）提供跟踪功能，包括但不限于识别其他用户在个人主页上查看、点击等操作行为；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>10</span>）自动将浏览器窗口定向到其他网页；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>11</span>）未经授权获取对本公司产品或服务的访问权；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>12</span>）应用中含有计算机病毒、木马或其他恶意程序等任何可能危害本公司或用户权益或终端信息安全等的内容；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>13</span>）设置或发布任何违反相关法规、公序良俗的功能或内容等；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>14</span>）明示或暗示您与本公司之间存在合作关系，包括但不限于相互持股、商业往来或合作关系等，或声称本公司对您的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>15</span>）填写和提交帐号注册与开发者认证资料违反本协议约定，或申请开发者认证资料与注册信息内容不符，以及运营行为与注册或开发者认证信息所公示身份无关的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>16</span>）以包括但不限于实物或虚拟物品奖励、胁迫、煽动用户分享等任何形式，实施强制、诱导其他用户关注帐号、点击链接页面、打开应用程序<span>APP</span>或分享信息等强制、诱导行为的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>17</span>）本公司认为不适当的其他行为。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.6.5</span>本服务中可能会使用已获合法授权的第三方软件或技术，本公司通过各种形式公示或送达的相关协议，均是本协议不可分割的组成部分。因本服务使用的第三方软件或技术引发的任何纠纷，由该第三方负责承担全部责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7</span>用户数据<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.1</span>就用户数据的收集、保存、使用，您的应用或服务应当满足以下条件：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）如需收集用户数据，应当事先获得用户的明确同意，范围仅限于为应用程序运行及功能实现目的而必要的用户数据，同时应当告知用户相关数据收集的目的、范围及使用方式等，以保障用户知情权；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）您收集用户的数据后，应当采取必要的保护措施，防止用户数据被盗、泄漏等；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）您在特定应用中收集的用户数据仅可以在该特定应用中使用，不得将其使用在该特定应用之外或为其他任何目的进行使用，也不得以任何方式将其提供给他人；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）您应当向用户告知修改、删除用户数据的方式，确保其自行操作完成，并确保相关数据被完全删除。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.2</span>未经本公司同意，您不得通过本服务获取、存储或要求用户提供本公司认为属于敏感信息范畴的数据，包括但不限于诺曼服务平台的信息、用户数据、诺曼密码、用户关系链、好友列表数据、银行账号和密码等），您不得将合法获得的前述数据自行或提供给其用户、客户用于创建、补充或维护自身关系链。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.3</span>未经本公司允许，您不得利用本服务所获得的数据实施或变相实施任何形式的推广、营销、广告行为。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.4</span>如果本公司认为您获取、使用用户数据的方式，可能损害用户体验，本公司有权要求您删除相关数据，并不得再以该方式获取、使用用户数据。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.5</span>本公司有权限制或阻止您获取用户数据及开放平台数据。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.6</span>诺曼开放平台相关数据（包括但不限于运营数据、用户数据等）属本公司商业秘密，相应权利，均归属本公司，除依法属于用户享有的相关权利除外。未经本公司事先书面同意，您不得为本协议约定之外的目的使用前述数据，亦不得以任何形式将前述数据提供给他人。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.7</span>一旦开发者停止使用本服务，或停止使用开放平台，或本公司基于任何原因终止您使用本服务，您必须立即删除全部从开放平台中获得的数据，且不得再以任何方式进行使用。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.7.8</span>您应对因使用本服务而存储在本公司服务器的各类数据等信息，采取合理、安全的技术措施，确保其安全性，并对自己的行为（包括但不限于自行安装软件、采取加密措施或实施其他安全措施等）所致后果承担全部责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.8</span>法律责任 <span></span>
	</p>
	<p class="MsoNormal">
		<span>2.8.1</span>您知悉并同意：本公司有权独立判断，认定您是否存在侵犯他人合法权益情形，本公司有权随时单方采取下列措施：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）对存在侵犯他人合法权益的应用或您名下的全部应用或任何一款应用采取封闭新用户入口、限制老用户登录等措施；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）对存在侵犯他人合法权益的公众帐号服务采取封闭新用户入口、限制老用户登录等措施；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）对存在侵犯他人合法权益的应用采取下线措施，即终止应用使用开放平台的服务；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）将您的行为对外予以公告，并依法追究您法律责任；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）要求您立即更换、修改侵犯他人合法权益的相关内容；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）禁止您今后将您的任何新应用接入开放平台；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>7</span>）其他合理措施。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.8.2</span>您知悉并同意，即便您对侵犯他人合法权益的应用进行修改后不再侵权，本公司仍有权对该应用进行下线处理，您可以将该应用作为新应用按照开放平台的流程、规范接入开放平台。<span></span>
	</p>
	<p class="MsoNormal">
		<span>2.8.3</span>您知悉并同意，本公司依据<span>2.8.1</span>款约定采取相应措施，由您自行承担全部责任，造成本公司或他人损失的，您应承当赔偿责任。<span></span>
	</p>
	<p class="MsoNormal">
		第三条
诺曼公司的权利和义务<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.1</span>本公司根据您选择的服务类型向您提供相应的服务。您理解并同意，基于用户体验、诺曼或其服务平台运营安全、平台规则及健康发展等综合因素，本公司有权单方选择提供服务或开展合作的对象，单方有权决定功能开放、数据接口和相关数据披露的对象和范围；开发者或应用提供本服务出现下列情形，本公司有权视情况单方中止或终止提供本服务：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）违反法律法规或本协议规定的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）影响使用者体验的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）存在安全隐患的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）与诺曼或其服务平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）界面、风格、功能、描述或使用者体验与诺曼或其服务平台类似，可能造成诺曼用户认为其所使用的功能或服务来源于本公司或经本公司授权的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）违背诺曼或其服务平台运营规则，或不符合本公司管理要求的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>7</span>）本公司认为应当中止或终止提供本服务的其他情形。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.2</span>为保护您的信息安全，未经您的同意，本公司不得向第三方披露您的信息，但以下情形除外：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）据本协议或其他相关协议、规则约定可以提供的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）依据法律法规的规定可以提供的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）行政、司法等政府部门要求提供的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）您同意本公司向第三方提供；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）为解决举报事件、提起诉讼而需要提供的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理措施所必须提供的。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.3</span>您知悉，即便本公司已尽信息安保义务，在现有的安全技术措施下，您的信息仍存在因为不可抗力或非本公司因素造成泄漏、窃取等风险，由此给您造成损失的，您同意本公司免责。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.4</span>本公司有权开发、运营与您应用相似或存在竞争关系的应用，同时本公司也不保证开放平台上不会出现其他开发者提供的与您应用存在竞争关系的应用。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.5</span>本公司有权在应用介绍页或安装页面等相应位置，向用户公示相关信息，以明确该应用为您自行开发，且由您向用户提供客户服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.6</span>本公司有权将本协议下的权利和义务的部分或全部转让给他人，若您不同意转让行为，您有权停止使用本协议下服务。继续使用本服务的行为，视为您同意。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.7</span>除了另行约定，本公司无需为本协议享有的权益而向您支付任何费用。<span></span>
	</p>
	<p class="MsoNormal">
		<span>3.8</span>您知悉并同意：为向更多互联网使用者推广您的应用，本公司有权采取以下行为，而无须取得您的同意：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）在诺曼开放平台以外的平台、网站等采取各种形式对公众帐号服务进行宣传、推广；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）本公司可根据整体运营安排，自主选择向全世界范围内的互联网用户提供您的公众帐号服务；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）有权使用您应用的<span>LOGO</span>、标识、名称、图片等相关素材。<span></span>
	</p>
	<p class="MsoNormal">
		第四条
免责条款<span></span>
	</p>
	<p class="MsoNormal">
		<span>4.1</span>您知悉并同意，鉴于网络服务的特殊性，本公司有权根据诺曼开放平台的整体运营情况或相关运营规范、规则等，单方决定变更、中止或终止部分或全部的服务，而无需通知您，由此给您造成损失的，您同意放弃追究本公司的责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>4.2</span>您知悉并同意，为了完善服务，本公司有权定期或不定期对平台或相关设备进行检修、维护、升级等，相关服务在合理时间内可能中断或暂停，给您造成损失的，您同意放弃追究本公司的责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>4.3</span>您知悉并同意，本公司提供的服务是基于现有技术和条件。鉴于无法预见和防范法律、技术以及其他风险（包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险等），您同意豁免本公司限于行业技术水平所无法避免的上述风险所致业务瑕疵的相关责任，不视为本公司违约，造成数据或信息丢失等损失的，您同意放弃追究本公司的责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>4.4 </span>您知悉并同意，以下情形导致的服务中断或受阻，给您造成损失的，您同意放弃追究本公司的责任：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）您或本公司的电脑软件、系统、硬件和通信线路出现故障；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）您操作不当；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>4</span>）您通过非本公司授权的方式使用本服务；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>5</span>）不可抗力；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>6</span>）其他本公司无法控制或合理预见的情形。<span></span>
	</p>
	<p class="MsoNormal">
		第五条
合同中止或终止<span></span>
	</p>
	<p class="MsoNormal">
		<span>5.1</span>以下情形，本公司有权单方解除合同，并不承担违约责任：<span></span>
	</p>
	<p class="MsoNormal">
		（<span>1</span>）您明示不接受本协议项下条款或本协议修改后的条款；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>2</span>）因不可抗力因，致您无法继续使用本服务，或本公司无法提供本服务的；<span></span>
	</p>
	<p class="MsoNormal">
		（<span>3</span>）本公司认为无法继续履行合同的其他情形。<span></span>
	</p>
	<p class="MsoNormal">
		<span>5.2</span>因您违约，本公司终止向您提供本服务后，您直接或间接，或以他人名义注册使用本服务的，本公司有权直接单方面暂停或终止提供本服务。<span></span>
	</p>
	<p class="MsoNormal">
		<span>5.3</span>协议终止，本公司有权将与您相关的全部信息保留或删除，包括但不限于您帐号中的全部数据、您因使用本服务而存储在本公司服务器中的全部数据、服务终止前您尚未完成的全部数据等。<span></span>
	</p>
	<p class="MsoNormal">
		<span>5.4</span>您应自行备份相关数据，并自行妥善处理您与用户之间的交互事宜，造成本公司损失的，您应负赔偿责任。<span></span>
	</p>
	<p class="MsoNormal">
		第六条
通知<span></span>
	</p>
	<p class="MsoNormal">
		<span>6.1</span>本公司以网页公告、网页提示、电子邮箱、手机短信、常规信件、站内信等方式，向您送达关于本服务的相关规则、通知、提示等信息，上述信息一经公布或发送，即视您已经接受并同意，对您产生约束力。<span></span>
	</p>
	<p class="MsoNormal">
		<span>6.2</span>送达地址以您注册时填写的相关信息为准，由于您提供的电子邮箱、手机号码、通讯地址等信息错误，导致您未收到相关规则、通知、提示等信息的，不影响送达效力，造成损失的，由您自负全部责任。<span></span>
	</p>
	<p class="MsoNormal">
		<span>6.3</span>您知悉并同意，本公司或合作伙伴向您的电子邮件、手机号码等发送本服务不相关的其他各类信息包括但不限于商业广告等。<span></span>
	</p>
	<p class="MsoNormal">
		<span>6.4</span>您应当按照本服务对外正式公布的联系方式，书面通知本公司。<span></span>
	</p>
	<p class="MsoNormal">
		第七条
知识产权<span></span>
	</p>
	<p class="MsoNormal">
		<span>7.1</span>除法律另有规定，本公司在本服务中提供的全部信息（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权，均归本公司所有。<span></span>
	</p>
	<p class="MsoNormal">
		<span>7.2</span>除另有声明，本公司提供本服务时所依托软件的著作权、专利权及其他知识产权均归本公司所有。<span></span>
	</p>
	<p class="MsoNormal">
		<span>7.3</span>除法律另有规定，本公司在本服务中所使用的“诺曼”、“诺曼公司”、“诺曼平台”等商业标识，其著作权或商标权归本公司所有。本公司依法拥有的知识产权均受到法律保护，未经本公司书面许可，您不得以任何形式进行使用或创造相关衍生作品。<span></span>
	</p>
	<p class="MsoNormal">
		<span>7.4</span>您知悉并保证，仅以本协议项下目的，合法使用本服务或相关<span>API</span>，与本服务相关的<span>API</span>相关的著作权、专利权等全部权利归本公司所有。未经本公司书面许可，您不得使用，不得向任何单位或个人转让或转授权本公司的代码、<span>API</span>及开发工具等。<span></span>
	</p>
	<p class="MsoNormal">
		第八条
其他<span></span>
	</p>
	<p class="MsoNormal">
		<span>8.1</span>本服务所涉相关协议均为本协议不可分割的组成部分，与本协议存在冲突的，以本协议为准。一经注册或使用本服务，即视为您已阅读并同意受本协议及所涉相关协议约束。本公司有权单方修改本协议及所涉相关协议，如您继续使用本服务，即视为您已接受修改后的条款。<span></span>
	</p>
	<p class="MsoNormal">
		<span>8.2</span>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。<span></span>
	</p>
	<p class="MsoNormal">
		<span>8.3</span>双方发生争议，应友好协商解决；协商不成，提交本公司所在地有管辖权的人民法院。<span></span>
	</p>
	<p class="MsoNormal">
		<span>8.4</span>本协议及相关协议条款的解释，以本公司解释为准。<span></span>
	</p>
	<p class="MsoNormal">
		<span>&nbsp;</span>
	</p>
	<p class="MsoNormal">
		<span>&nbsp;</span>
	</p>
	<p class="MsoNormal">
		<span>&nbsp;</span>
	</p>
</p>
<p>
</p>`);
    const checked = ref(false);
    const showToast = ref(true);
    function toAuth() {
      if (!checked.value) {
        open4();
        return;
      }
      router.push(`/appAdd/add`);
    }
    function open4() {
      if (!showToast.value) {
        return;
      }
      showToast.value = false;
      ElMessage.warning({
        message: "您还未勾选同意该协议",
        type: "warning",
        onClose: () => {
          showToast.value = true;
        },
      });
    }
    return {
      richContent,
      checked,
      toAuth,
    };
  },
  components: {
    Application,
  },
};
</script>

<style scoped lang="less">
.applicationP-pro {
  text-align: left;
  width: 1200px;
  margin: auto;
  .protocol-subject {
    color: #000;
    font-size: 18px;
    margin-top: 30px;
  }
  .rich-text {
    width: 100%;
    height: 380px;
    margin-top: 10px;
    background: #fff;
    overflow: auto;
    padding: 10px 20px;
  }
  .el-checkbox {
    margin-top: 20px;
  }
  .el-button {
    display: block;
    margin-top: 20px;
    width: 100px;
  }
}
</style>