<template>
    <div class="addSign">
        <div class="topCol">
            <arrowComp streamer="上传制作印章"></arrowComp>
        </div>
        
        <div class="addCol clearfix">
            <div class="uploadImg">
                <img v-if="signImgUrl" :src="signImgUrl" class="avatar">
            </div>
            <div class="uploadInfo">
                <div class="info">
                    <label for="signName">印章名称：</label>
                    <el-input id="signName" placeholder="印章名称" v-model="signInfo.name"></el-input>
                </div>
                <div class="info">
                    <label for="signPass">印章密码：</label>
                    <el-input id="signPass" placeholder="印章密码" v-model="signInfo.pass" show-password></el-input>
                </div>
                <div class="uploadBtn">
                    <el-upload
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :multiple="false"
                        :show-file-list="false"
                        :on-change="applyChange"
                        :before-upload="beforeUpload"
                        :on-success="uploadSuccess"
                        :on-remove="handleRemove">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                            只能上传 jpg/png 图片，且不超过 10MB
                            </div>
                        </template>
                    </el-upload>
                </div>
            </div>
        </div>

        <div class="btn">
            <el-button type="primary" class="save" size="medium" @click="saveSign">保存</el-button>
        </div>

        <div class="tips">
            <p>
				1.拍摄印章图片：<b style="color: red">将你的公章盖在白纸中央位置，之后拍照（确保光线充足，不能有太重的遮挡阴影）。</b><br>
				2.用户无需自己对图片进行裁剪或者抠图处理，请直接上传图片，系统将会自动裁剪用户图片，并进行透明化处理，
				  只要符合条件的图片，系统都会处理成功。如果上传之后用户看到的图片效果不佳，请重新拍摄图片，之后再上传。<br>
				3.目前支持对jpg格式图片的处理，请不要上传其他格式的图片<br>
				4.如果看到的图片效果不佳，继续保存该印章，那么在审核的时候也是不会通过的。<br>
				5.如果还是不清楚如何操作，请联系诺曼签约客服人员，请求协助。<br>
				6.查看符合要求的印章图片<a><b style="color: red"></b></a><br>
				<img src="../assets/img/ysyz.png" style="width:150px; margin-left: 20px;position: absolute;bottom: 0px; right: 0px;">
			</p>
        </div>
    </div>
</template>

<script>
import { inject, reactive, toRefs } from 'vue'
import { ElMessage  } from "element-plus";
import arrowComp from '../components/banner'
import { useRouter } from 'vue-router';

export default {
    name: "addSign",
    components: {
        arrowComp
    },
    setup(){
        const useAxios = inject("useAxios");
        const router = useRouter();

        const data = reactive({
            signImgUrl: '',
            dialogVisible: false,
            signInfo: {
                name: '',
                pass: '',
                url: ''
            }
        })

        // 上传
        let applyChange = (file, fileList) => {
            // console.log(file)
            const isJPG = file.raw.type === 'image/jpg';
            const isJPEG = file.raw.type === 'image/jpeg';
            const isPNG = file.raw.type === 'image/png';
            const isLt10M = file.raw.size / 1024 / 1024 < 10;
            if (!isJPG && !isJPEG && !isPNG) {
                ElMessage.error('只能上传 jpg/png 图片!');
                return
            }
            if (!isLt10M) {
                ElMessage.error('上传图片大小不能超过 10MB!');
                return
            }

            // 处理图片
            getBase64(file.raw).then(res => {
                data.signInfo.url = res.split(',')[1];
            }).then(()=>{
                // 请求
                if(data.signInfo.url){
                    goSolveImg();
                }
            });
        }

        let getBase64 = (file) => {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                };
                reader.onerror = function(error) {
                    reject(error);
                };
                reader.onloadend = function() {
                    resolve(imgResult);
                };
            });
        }

        // 处理图片
        let goSolveImg = () => {
            useAxios.post("/foreground/web/company/seal/image/make", {sealBase64: data.signInfo.url}).then((res) => {
                if(res.data.code == "0"){
                    data.signImgUrl = res.data.attach.sealBase64;
                }
            }).catch((err) => {});
        }
        let uploadSuccess = (res, file) => {
            // console.log(res)
        }
        let beforeUpload = (file) => {
            // console.log(file)
        }
        let handleRemove = (file, fileList) => {
            // console.log(file, fileList);
        }

        // 保存
        let saveSign = () => {
            let obj = {
                "sealBase64": data.signImgUrl.split(',')[1],
                "sealPassword": data.signInfo.pass,
                "userName": data.signInfo.name
            }
            useAxios.post("/foreground/web/company/seal/add", obj).then((res) => {
                if(res.data.code == "0"){
                    ElMessage.success('保存成功!');
                    router.push('signManage');
                }
            }).catch((err) => {});
        }

        return{
            ...toRefs(data),
            applyChange,
            uploadSuccess,
            beforeUpload,
            handleRemove,
            saveSign
        }
    },
}
</script>

<style lang="less" scoped>
.addSign{
    padding: 0 250px;
    .topCol{
        width: 100%;
        overflow: hidden;
    }
    .addCol{
        width: 100%;
        height: 300px;
        padding-top: 40px;
        box-sizing: border-box;
        .uploadImg{
            width: 200px;
            height: 200px;
            line-height: 200px;
            float: left;
            background-color: #fbfdff;
            border: 1px dashed #c0ccda;
            border-radius: 6px;
            box-sizing: border-box;
            margin-left: 30px;
            .avatar {
                width: 200px;
                height: 200px;
                display: block;
            }
            // /deep/.sign-uploader .el-upload {
            //     background-color: #fbfdff;
            //     border: 1px dashed #c0ccda;
            //     border-radius: 6px;
            //     box-sizing: border-box;
            //     cursor: pointer;
            //     position: relative;
            //     overflow: hidden;
            // }
            // /deep/.sign-uploader .el-upload:hover {
            //     border-color: #409EFF;
            // }
            // .avatar-uploader-icon {
            //     font-size: 28px;
            //     color: #8c939d;
            //     width: 200px;
            //     height: 200px;
            //     line-height: 200px;
            //     text-align: center;
            // }
        }
        .uploadInfo{
            width: 35%;
            float: left;
            padding-left: 50px;
            .info{
                display: flex;
                height: 40px;
                line-height: 40px;
                margin-bottom: 24px;
                label{
                    width: 120px;
                }
            }
            .uploadBtn{
                text-align: left;
                padding-top: 10px;
            }
        }
    }
    .btn{
        .el-button{
            width: 24%;
        }
    }
    .tips{
        p{
            width: 72%;
            position: relative;
            margin: auto;
            padding: 10px;
            margin-top: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #707478;
            text-align: left;
            border: 1px solid #c3c3c3;
            border-radius: 6px;
        }
    }
}
</style>