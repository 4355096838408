<template>
  <div>
    <div class="mation_content">
      <div class="topCol">
        <arrowComp :streamer="data.streamerMsg"></arrowComp>
        <br/><br/>
        <div class="mation_right fl">
          <p class="dian_p crea cur" @click="addIndividual()">添加乙方个人</p>
          <div class="tScroll">
            <table class="pp">
              <tbody>
              <tr class="mm">
                <td class="bb bi">
                  <p style="display: inline-block" class="tableWith">用户名</p>
                </td>
                <td class="bb bi">
                  <p style="display: inline-block" class="tableWith">手机号</p>
                </td>
                <td class="bb bi">
                  <p style="display: inline-block" class="tableWith">身份证号</p>
                </td>
                <td class="bb bi">
                  <p style="display: inline-block" class="tableWith">创建时间</p>
                </td>
                <td class="bb bi">
                  <p style="display: inline-block" class=" tableLastColumnWith border_right" > 操作 </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="empty-list" v-if="data.individualList.length == 0">你还已添加乙方个人</div>
                </td>
              </tr>

              <tr class="nn" v-for="info in data.individualList" :key="info.id">
                <td
                    class="cc c_text ct tableWith"
                    align="center"
                >

                  <div class="nxd">{{info.userName}}</div>
                </td>
                <td
                    class="cc c_text tableWith"
                >
                  <div class="nxd">{{info.phone}}</div>
                </td>
                <td
                    class="cc c_text tableWith"
                >
                  <div class="nxd">{{info.userCard}}</div>
                </td>
                <td
                        class="cc c_text tableWith"
                >

                  <div class="nxd">{{info.createTime}}</div>
                </td>
                <td class="cc c_text tableLastColumnWith">
                  <div class="nxd">
                    <span @click="cancel(info.id)" class="cur delOpeClass">删除</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive,inject} from "vue";
import arrowComp from "@/components/banner";
import { ElMessage,ElMessageBox } from "element-plus";
import {  useRouter } from 'vue-router';

export default {
  name: "Individual",

  setup(){
    const useAxios = inject("useAxios");
    const router = useRouter();
    const data = reactive({
      streamerMsg :  "乙方个人管理",
      individualList:[],
    });
    const initFun =()=>{
      getIndividualList();
    };
    //跳转协议
    function addIndividual(){
      router.push("/appCenter/individualAdd");
    };

    //乙方个人列表
    function getIndividualList(){
      useAxios.post('/foreground/web/companySignTool/companySignIndividualUserList').then(res=>{
        if(res.data.code == 0){
          data.individualList = res.data.data
        }
      })
    };
    //取消授权
    function cancel(id){
      ElMessageBox.confirm('你确定要删除该乙方个人吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        useAxios.get('/foreground/web/companySignTool/deleteCompanySignIndividualUser/'+id,{
        }).then(res=>{
          if(res.data.code == 0){
            ElMessage.success('删除成功');
            getIndividualList();
          };
        });
      })
    };
    // 复制
    onMounted(()=>{
      initFun();
    });

    return {
      data,
      addIndividual,
      cancel
    };
  },
  components:{
    arrowComp
  },

}
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto !important;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px !important;
  padding-bottom: 10px;
  .mation_right {
    width: 100%;
    height: auto;
    clear: both;
  }
  .crea{
    color: #fff;
    font-size: 16px;
    padding: 5px 25px;
    float: right;
    background: #0078FF;
    border-radius: 20px;
    font-family: '微软雅黑'
  }
  .tScroll{
    width: 100%;
    clear: both;
    overflow-x: scroll;
    cursor: default;
    text-align: center;
    margin-top: 40px;
    padding-bottom: 15px;
    display: block;
    border: 2px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
    .pp{
      width: 100%;
      .mm{
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid #000;
        box-sizing: border-box;
        .bb {
          padding: 15px 0px;
          height: 52px;
          color: #0078FF;
          font-size: 15px;
          text-align: center;
          font-weight: bold;
          p{
            height: 20px;
            border-right: 2px #000 solid;
            box-sizing: border-box;
            margin: 0 0 10px;
          }
        }
      }
      .nn{
        height: 60px;
        .c_text{
          line-height: 66px;
          height: 66px;
          font-size: 14px;
          display: block;
          float: left;
          font-family: "微软雅黑" !important;
          div{
            padding: 0px 5px;
          }
          .nxd{
            span{
              color: #000;
            }
            span:hover{
              color: #0078FF;
            }
          }
        }
        .cc{
          color: #000;
          text-align: center;
          .delOpeClass{
            font-weight: bold;
            color: #bd362f !important;
          }
        }
      }
    }
  }
}
.tableWith{
   width: 250px;
 }
.tableLastColumnWith{
  width: 200px;
}
@media screen and (max-width: 1280px) {
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .mation_content {
    width: 90%;
  }
}
.cur {
  cursor: pointer;
}

table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}
::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 10px 0px;
}
::-webkit-scrollbar {
  height: 5px !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
table,tbody,tr,td,p{
  box-sizing: border-box;
}
.empty-list{
  text-align: center;
  color:#000;
  font-weight: 900;
  margin-top: 15px;
}
</style>
