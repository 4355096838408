import { createStore } from 'vuex'

export default createStore({
	state: {
		mainTabsStatus: sessionStorage.getItem('mainTabsStatus'),
		userTabsStatus: sessionStorage.getItem('userTabsStatus'),
		userToken: sessionStorage.getItem('token')
	},
	mutations: {
		updateMainTab(state){
			state.mainTabsStatus = sessionStorage.getItem('mainTabsStatus');
		},
		changeUserTabs(state){
			state.userTabsStatus = sessionStorage.getItem('userTabsStatus');
		},
		updateToken(state){
			state.userToken = sessionStorage.getItem('token');
		}
	},
	actions: {
		goChangeUserTabs(context){
			context.commit('changeUserTabs');
		}
	},
	modules: {

	}
})
