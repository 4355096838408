<template>
  <div class="appadd">
    <div class="mation_content">
        <arrowComp :streamer="form.streamerMsg"></arrowComp>
      <div class="mation_right">
          <label class="z_name mc"><span>*</span>乙方个人姓名：</label>
          <input
            class="z_inp mp"
            type="text"
            v-model="form.userName"
          />

          <label class="z_name mc"><span>*</span>手机号：</label>
          <input
            class="z_inp mp"
            type="text"
            v-model="form.phone"
          />
          <label class="z_name mc"><span>*</span>身份证号：</label>
          <input
                  class="z_inp mp"
                  type="text"
                  v-model="form.userCard"
          />
          <div class="sty_box">
            <el-button type="primary" @click="save">添加完成</el-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrowComp from "@/components/banner";
import { reactive,inject,onMounted } from 'vue';
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "IndividualAdd",
  setup() {
    const useAxios = inject("useAxios");
    const router = useRouter();
    const form = reactive({
        userName:'',
        phone:'',
        streamerMsg:'添加已方个人'
    });
    //保存应用
    function save(){
      if(form.userName == ''){
        ElMessage.error('乙方个人姓名不能为空');
        return;
      }else if(form.phone == ''){
        ElMessage.error('身份证号不能为空');
        return;
      }else if(form.userCard == ''){
          ElMessage.error('手机号不能为空');
          return;
      }
      const data = {
        userName:form.userName,
        phone: form.phone,
        userCard: form.userCard,
      }
      useAxios.post('/foreground/web/companySignTool/createCompanySignIndividualUser',data).then(res=>{
        if(res.data.code == 0){
          ElMessage.success('添加成功');
          setTimeout(() => {
              router.push("/appCenter/individual");
          }, 1500);
        }else{
            ElMessage.error(res.data.msg);
        }
      }).catch(err=>{
      });
    };
    return {
      form,
      save
    }
  },
  components: {
      arrowComp,
  },
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px;
  padding-bottom: 10px;
  .mation_right {
    width: 100%;
    height: auto;
    clear: both;
    text-align: left;
    .mc{
      width: 20%;
      box-sizing: border-box;
      display: inline-block;
    }
    .z_name{
      color: #000;
      font-size: 16px;
      margin-top: 25px;
      text-align: right;
      padding-right: 6px;
      font-family: "微软雅黑";
      outline: none;
      span{
        color: red;
        font-size: 16px;
      }
    }
    .mp{
      width: 29%;
      box-sizing: border-box;
    }
    .z_inp{
      height: 35px;
      border: 2px solid #000;
      border-radius: 6px;
      margin-top: 14px;
      padding-left: 6px;
    }
    .sty_box{
      width: 100%;
      clear: both;
      padding-top: 100px;
      text-align: center;
    }
  }
}
.el-button{
  padding: 12px 90px;
}
@media screen and (max-width: 1280px){
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px){
  .mation_content {
      width: 90%;
  }
}
</style>
