<template>
  <div class="appadd">
    <div class="mation_content">
      <Application streamer="创建应用"></Application>
      <div class="mation_right">
          <label class="z_name mc"><span>*</span>应用名称：</label>
          <input
            class="z_inp mp"
            type="text"
            v-model="form.app_name"
          />

          <label class="z_name mc"><span>*</span>类型：</label>
          <select class="z_inp mp" v-model="form.ddtype">
            <option value="0">自用</option>
            <option value="1">第三方</option>
          </select>

          <label class="z_name mc" style="vertical-align: top;">应用描述：</label>
          <textarea
            class="z_inp mp"
            rows="5"
            cols="20"
            v-model="form.desc"
          ></textarea>

          <div class="sty_box">
            <el-button type="primary" @click="save" :disabled="form.bnDisabled" v-if="type == 'save'">保存</el-button>
            <el-button type="primary" @click="updataInfo" :disabled="form.bnDisabled" v-if="type == 'updata'">修改</el-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Application from "@/components/application.vue";
import { reactive,inject, onMounted, ref } from 'vue';
import { ElMessage  } from "element-plus";
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "AppAdd",
  setup() {
    const useAxios = inject("useAxios");
    const router = useRouter();
    const route = useRoute()
    const form = reactive({
      app_name:'',
      ddtype:0,
      desc:'',
      bnDisabled:false
    });
    const type = ref('save');
    onMounted(()=>{
      if(route.params.id !== 'add'){
        echoData();
        type.value = 'updata';
      }
    })
    //保存应用
    function save(){
      if(form.app_name == ''){
        ElMessage.error('应用名称不能为空');
        return;
      }
      form.bnDisabled = true;
      const data = {
        appName:form.app_name,
        appType: form.ddtype,
        appDesc: form.desc,
      }
      useAxios.post('/foreground/web/company/app/add',data).then(res=>{
        if(res.data.code == 0){
          form.bnDisabled = false;
          ElMessage.success('创建成功');
          setTimeout(() => {
            router.push({
              path:'/appCenter'
            })
          }, 1500);
        }else{
          form.bnDisabled = false;
        }
      }).catch(err=>{
        form.bnDisabled = false;
      });
    };

    //修改数据
    function updataInfo (){
      if(form.app_name == ''){
        ElMessage.error('应用名称不能为空');
        return;
      }
      form.bnDisabled = true;
      const data = {
        appName:form.app_name,
        appType: form.ddtype,
        appDesc: form.desc,
        id:route.params.id
      }
      useAxios.post('/foreground/web/company/app/update',data).then(res=>{
        if(res.data.code == 0){
          form.bnDisabled = false;
          ElMessage.success('修改成功');
          setTimeout(() => {
            router.back();
          }, 1500);
        }else{
          form.bnDisabled = false;
        }
      }).catch(err=>{
        form.bnDisabled = false;
      });
    };
    //回显数据
    function echoData(){
      useAxios.get(`/foreground/web/company/app/info?id=${route.params.id}`).then(res=>{
        if(res.data.code == 0 && res.data.attach){
          form.app_name = res.data.attach.appName;
          form.ddtype = res.data.attach.appType;
          // form.app_notify_url = res.data.attach.appNotifyUrl;
          // form.app_notify_port = res.data.attach.appNotifyPort;
          form.desc = res.data.attach.appDesc;
        }
      })
    }
    return {
      form,
      save,
      type,
      updataInfo
    }
  },
  components: {
    Application,
  },
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px;
  padding-bottom: 10px;
  .mation_right {
    width: 100%;
    height: auto;
    clear: both;
    text-align: left;
    .mc{
      width: 20%;
      box-sizing: border-box;
      display: inline-block;
    }
    .z_name{
      color: #000;
      font-size: 16px;
      margin-top: 25px;
      text-align: right;
      padding-right: 6px;
      font-family: "微软雅黑";
      outline: none;
      span{
        color: red;
        font-size: 16px;
      }
    }
    .mp{
      width: 29%;
      box-sizing: border-box;
    }
    .z_inp{
      height: 35px;
      border: 2px solid #000;
      border-radius: 6px;
      margin-top: 14px;
      padding-left: 6px;
    }
    .sty_box{
      width: 100%;
      clear: both;
      padding-top: 100px;
      text-align: center;
    }
  }
}
.el-button{
  padding: 12px 90px;
}
@media screen and (max-width: 1280px){
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px){
  .mation_content {
      width: 90%;
  }
}
</style>