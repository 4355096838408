<template>
  <div class="changePass">
    <div class="mation_content">
      <Wd streamer="修改密码"></Wd>
      <div class="mation_right fl">

          <div class="form-group m-b-7 mtop">
            <label class="namm fgt f_t">原密码：</label>
            <input
              type="text"
              class="lo_inputq fgt"
              placeholder="请填写原密码"
              v-model="passwordInfo.oldPass"
            />
          </div>
          <div class="form-group m-b-7">
            <label class="namm fgt f_t">设置密码：</label>
            <input
              type="password"
              class="lo_inputq fgt"
              placeholder="请设置不可小于8位数的密码"
              v-model="passwordInfo.password"
            />
          </div>
          <div class="form-group m-b-7">
            <label class="namm fgt f_t">确认密码：</label>
            <input
              type="password"
              class="lo_inputq fgt"
              placeholder="请确认您的密码"
              v-model="passwordInfo.affirmPass"
            />
          </div>

          <div class="login-buttond">
            <el-button type="primary" @click="validation()" :disabled="passwordInfo.btDisabled">确定</el-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive,inject } from 'vue';
import Wd from '@/components/banner.vue'
import { useRouter } from 'vue-router';
import { ElMessage } from "element-plus";
export default {
  name: "ChangePass",
  setup(){
    const router = useRouter();
    const useAxios = inject("useAxios");
    const passwordInfo = reactive({
      oldPass:'',
      password:'',
      affirmPass:'',
      btDisabled:false
    });
    //确定
    function validation(){
      if(passwordInfo.oldPass == ''){
        ElMessage.error('请输入旧密码');
        return;
      }else if(passwordInfo.password == ''){
        ElMessage.error('请输入新密码');
        return;
      }else if(passwordInfo.affirmPass == ''){
        ElMessage.error('请输入确认密码');
        return;
      }else if(passwordInfo.affirmPass != passwordInfo.password){
        ElMessage.error('新密码两次输入不一致');
        return;
      }else if(passwordInfo.oldPass == passwordInfo.password){
        ElMessage.error('新密码不能和旧密码一样');
        return;
      }
      passwordInfo.btDisabled = true;
      const info = {
        oldPassword: passwordInfo.oldPass,
        newPassword: passwordInfo.password,
        repeatNewPassword:passwordInfo.affirmPass  
      };
      useAxios.post('/foreground/web/company/password/update',info).then(res=>{
        if(res.data.code == 0){
          if(res.data.attach.successFlag == 1){
            ElMessage.success('修改成功');
            setTimeout(() => {
                window.location.replace('/#/login');
                sessionStorage.clear();
            }, 1500);
          }else{
            ElMessage.error('修改失败');
            passwordInfo.btDisabled = false;
          };
        }else{
          passwordInfo.btDisabled = false;
        }
      }).catch(err=>{
        passwordInfo.btDisabled = false;
      })
    };
    return{
      passwordInfo,
      validation
    }
  },
  components:{
    Wd
  }
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto !important;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px !important;
  padding-bottom: 10px;
  .mation_right{
    width: 100%;
    height: auto;
    clear: both;
    text-align: left;
    .namm{
      width: 27%;
      color: #000;
      font-size: 18px;
      text-align: right;
      display: inline-block;
    }
    .fgt{
      font-weight: lighter;
    }
    .lo_inputq {
      width: 40%;
      line-height: 50px;
      height: 50px;
      color: #000;
      background: transparent;
      font-size: 18px;
      padding-left: 5%;
      border: none;
      border-radius: 4px;
    }
    .login-buttond{
      width: 50%;
      margin: auto;
      margin-bottom: 90px;
      .el-button{
        margin-top: 40px;
        width: 50%;
        height: 33px;
        line-height: 33px;
        padding: 0 20px;
      }
    }
  }
}


.mtop{
  margin-top: 30px ;
}
.m-b-7 {
  margin-bottom: 8px!important;
}
.form-group{
  width: 100%;
}
@media screen and (max-width: 1280px){
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px){
  .mation_content {
      width: 90%;
  }
}
</style>
