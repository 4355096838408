<template>
  <div class="getAuthUser">
    <div class="mation_content">
      <Wd streamer="被授权用户"></Wd>

      <div class="mation_right fl">
<!--        <p class="dian_p crea cur" @click="checkAgree">去授权</p>-->
        <div class="tScroll">
          <table class="pp">
            <tbody>
              <tr class="mm">
                <td class="bb bi">
                  <p style="width: 340px; display: inline-block">被授权用户名称</p>
                </td>
                <td class="bb bi">
                  <p style="width: 300px; display: inline-block">授权时间</p>
                </td>
                <td class="bb bi">
                  <p style="width: 270px; display: inline-block">状态</p>
                </td>
                <td class="bb bi">
                  <p style="width: 280px; display: inline-block" class="border_right" > 操作 </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="empty-list" v-if="data.authUserList.length == 0">该应用还没有被授权的用户</div>
                </td>
              </tr>
              <tr class="nn" v-for="info in data.authUserList" :key="info.id">
                <td
                  class="cc c_text ct"
                  align="center"
                  style="width: 340px"
                >
                  <div class="nxd">{{info.userName}}</div>
                </td>
                <td
                  class="cc c_text"
                  style="width: 300px"
                >
                  <div class="nxd">{{info.updateTime}}</div>
                </td>
                <td
                  class="cc c_text"
                  style="width: 270px"
                >
                  <div class="nxd">授权中</div>
                </td>
                <td class="cc c_text" style="width: 280px">
                  <div class="nxd">
                    <span @click="cancel(info.id)" class="cur">取消被授权</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wd from "@/components/banner.vue";
import { useRoute } from 'vue-router';
import { reactive,inject, onMounted } from 'vue';
import { ElMessage,ElMessageBox } from "element-plus";
export default {
  name: "GetAuthUser",
  components: {
    Wd,
  },
  setup(){
    const useAxios = inject("useAxios");
    const route = useRoute();
    const data = reactive({
      authUserList:[],
    });

    onMounted(()=>{
      if(route.params.appKey){
        getAuthUserList();
      }
    })


    //授权列表
    function getAuthUserList(){
      useAxios.get(`/foreground/web/company/auth/getAuthUser?appKey=${route.params.appKey}`).then(res=>{
        if(res.data.code == 0){
          data.authUserList = res.data.attach
        }
      })
    };

    function cancel(id){
      ElMessageBox.confirm('你确定要取消被授权吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        useAxios.get('/foreground/web/company/auth/delete/'+id,{
        }).then(res=>{
          if(res.data.code == 0){
            ElMessage.success('删除成功');
            getAuthUserList();
          };
        });
      })
    };

    //跳转协议
    function checkAgree(){
      route.push('/userCenter/authProtocol');
    }
    return {
      cancel,
      // checkAgree,
      data
    }
  }
};
</script>

<style lang="less" scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto !important;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px !important;
  padding-bottom: 10px;
  .mation_right {
    width: 100%;
    height: auto;
    clear: both;
  }
  .crea{
    color: #fff;
    font-size: 16px;
    padding: 5px 25px;
    float: right;
    background: #0078FF;
    border-radius: 20px;
    font-family: '微软雅黑'
  }
  .tScroll{
    width: 100%;
    clear: both;
    overflow-x: scroll;
    cursor: default;
    text-align: center;
    margin-top: 40px;
    padding-bottom: 15px;
    display: block;
    border: 2px solid #000;
    border-radius: 5px;
    box-sizing: border-box;
    .pp{
      width: 100%;
      .mm{
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid #000;
        box-sizing: border-box;
        .bb {
          padding: 15px 0px;
          height: 52px;
          color: #0078FF;
          font-size: 15px;
          text-align: center;
          font-weight: bold;
          p{
            height: 20px;
            border-right: 2px #000 solid;
            box-sizing: border-box;
            margin: 0 0 10px;
          }
        }
      }
      .nn{
        height: 60px;
        .c_text{
          line-height: 66px;
          height: 66px;
          font-size: 14px;
          display: block;
          float: left;
          font-family: "微软雅黑" !important;
          div{
            padding: 0px 5px;
          }
          .nxd{
            span{
              color: #000;
            }
            span:hover{
              color: #0078FF;
            }
          }
        }
        .cc{
          color: #000;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .mation_content {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .mation_content {
    width: 90%;
  }
}
.cur {
  cursor: pointer;
}
table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}
::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 10px 0px;
}
::-webkit-scrollbar {
    height: 5px !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
table,tbody,tr,td,p{
  box-sizing: border-box;
}
.empty-list{
  text-align: center;
  color:#000;
  font-weight: 900;
  margin-top: 15px;
}
</style>
