<template>
  <div class="tem-box">
    <header>
      <div class="logo_bg">
        <div class="logo_box">
          <img class="logo fl" src="../assets/img/logo.png"/>
          <div class="fan_hui fg" style="cursor: pointer;" @click="toLogin"></div>
          <div class="navigato fg" v-if="showSkip">如已注册，<span @click="toLogin">马上登录</span></div>
        </div>
      </div>
    </header>
    <div class="login-v2 login_d">
      <div class="login">
        <div class="p_bx">
          <img src="../assets/img/qian_z.png"/>
        </div>
        <div class="p_bx1">
            <img src="../assets/img/de_l.png" class="p_image">
            <div class="p_img">
              <slot/>
            </div>
        </div>
      </div>
      <div class="num_box1">COPYRIGHT © 2021 诺曼签约 版权所有.    辽ICP备17006874号-1</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: "LoginTemplate",
  props: {
      showSkip: Boolean,
  },
  setup(props,cex){
    const router = useRouter();
    function toLogin(){
      router.push("/login");
    }
    return {toLogin}
  }
};
</script>

<style scoped lang="less">
header{
  height: 100px;
  .logo_bg{
    font-family: "微软雅黑";
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .logo_box{
      width: 80%;
      margin: 0% 10%;
      height: 100%;
      @media only screen and (max-width: 1580px){
        width: 90% !important;
        margin: 0% 5% !important;
      }
      .logo{
        display: block;
        float: left;
        margin-top: 30px;
        width: 112px;
        line-height: 100px;
      }
      .fan_hui{
        width: 42px;
        height: 38px;
        float: right;
        line-height: 74px;
        color: #fff;
        margin-top: 28px;
        font-size: 15px;
        background-image: url(../assets/img/sy1.png);
      }
      .navigato{
        height: 100px;
        line-height: 100px;
        margin-right: 20px;
        float: right;
        font-size: 16px;
        color: #000;
        span{
            color: #66ACFA;
            cursor: pointer;
        }
      }
    }
  }
}
.tem-box{
  width: 100%;
  height: 100vh;
  background: url(../assets/img/bj.jpg) repeat fixed center center !important;
  background-size: 100%,100%;
  position: relative;
}
.login-v2{
  margin: 0 auto;
}
.login {
  position: relative;
  display: flex;
  .p_bx{
    width: 55%;
    margin-top: 80px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .p_bx1{
    width: 38%;
    margin-left: 7%;
    margin-bottom: 50px;
    position: relative;
    .p_image{
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
    }
    .p_img{
      padding: 30% 17%;
      position: relative;
      z-index: 1;
    }
  }
}
.login_d {
  width: 70%;
    margin: auto;
}
.num_box1 {
    width: 100%;
    height: 74px;
    line-height: 74px;
    text-align: center;
    color: #000;
    position: fixed;
    left: 0px;
    bottom: 0px;
    font-size: 16px;
    z-index: 9999;
}
@media only screen and (max-width: 1690px){
  .login_d {
    width: 80%;
    }
}
@media only screen and (max-width: 1580px){
    .login_d {
        width: 90%;
    }
}
</style>
