import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import AdvancedApp from '../views/AdvancedApp.vue'
import UserCenter from '../views/UserCenter.vue'
import AppCenter from '../views/AppCenter.vue'
import SmallTool from '../views/SmallTool.vue'
import AppAdd from '../views/AppAdd.vue'
import ApplicationPro from '../views/ApplicationPro.vue'
import GetAuthUser from '../views/GetAuthUser.vue'
import Examine from '../views/Examine.vue'

import BasicInfo from '../views/BasicInfo.vue'
import SignManage from '../views/SignManage.vue'
import MyAuth from '../views/MyAuth.vue'
import ChangePass from '../views/ChangePass.vue'
import SecretKeyAndIv from '../views/SecretKeyAndIv.vue'
import AuthList from '../views/AuthList.vue'
import AuthProtocol from '../views/AuthProtocol.vue'
import AddSign from '../views/AddSign.vue'
import EditSign from '../views/EditSign.vue'
import ViewBasicInfo from '../views/ViewBasicInfo.vue'
import UpdateBasicInfo from '../views/UpdateBasicInfo.vue'
import Staff from '../views/Staff.vue'
import StaffAdd from '../views/StaffAdd.vue'
import IndividualAdd from '../views/IndividualAdd.vue'
import Individual from '../views/Individual.vue'
import TemplateManage from '../views/TemplateManage.vue'
import UpdatePass from '../views/UpdatePass.vue'

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },

    {
        path: '/userCenter',
        name: 'UserCenter',
        component: UserCenter,
        children: [
            {
                path: '',
                name: 'BasicInfo',
                component: BasicInfo
            },
            {
                path: 'signManage',
                name: 'SignManage',
                component: SignManage
            },
            {
                path: 'myAuth',
                name: 'MyAuth',
                component: MyAuth
            },
            {
                path: 'changePass',
                name: 'ChangePass',
                component: ChangePass
            },
            {
                path: 'secretKeyAndIv',
                name: 'SecretKeyAndIv',
                component: SecretKeyAndIv
            },
            {
                path: 'authList',
                name: 'AuthList',
                component: AuthList
            },
            {
                path: 'authProtocol',
                name: 'AuthProtocol',
                component: AuthProtocol
            },
            {
                path: 'addSign',
                name: 'AddSign',
                component: AddSign
            },
            {
                path: 'editSign/:type',
                name: 'EditSign',
                component: EditSign
            },
            {
                path: 'viewBasicInfo',
                name: 'ViewBasicInfo',
                component: ViewBasicInfo
            },
            {
                path: 'updateBasicInfo/:userId',
                name: 'UpdateBasicInfo',
                component: UpdateBasicInfo
            }
        ]
    },
    {
        path: '/about',
        name: 'About',
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/examine/:id',
        name: 'Examine',
        component: Examine
    },
    {
        path: '/appAdd/:id',
        name: 'AppAdd',
        component: AppAdd
    },
    {
        path: '/applicationPro',
        name: 'ApplicationPro',
        component: ApplicationPro
    },
    {
        path: '/getAuthUser/:appKey',
        name: 'GetAuthUser',
        component: GetAuthUser,
    },
    {
        path: '/appCenter',
        name: 'AppCenter',
        component: AppCenter,
        children: [
            {
                path: '',
                name: 'AdvancedApp',
                component: AdvancedApp
            },
            {
                path: 'smallTool',
                name: 'SmallTool',
                component: SmallTool
            },
            {
                path: 'staff',
                name: 'Staff',
                component: Staff
            },
            {
                path: 'staffAdd',
                name: 'StaffAdd',
                component: StaffAdd
            },
            {
                path: 'individualAdd',
                name: 'IndividualAdd',
                component: IndividualAdd
            },
            {
                path: 'individual',
                name: 'Individual',
                component: Individual
            },
            {
                path: 'templateManage',
                name: 'TemplateManage',
                component: TemplateManage
            },
        ]
    },
    {
        path: '/updatePass',
        name: 'UpdatePass',
        component: UpdatePass
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
// router.beforeEach((to, from, next) => {
// 	if ( to.path == '/login' || to.path == '/'  || to.path == '/register') {
// 		// 登录或者注册才可以往下进行
// 		next();
// 	} else {
// 		// 获取 token
// 		const token = sessionStorage.getItem('token');
// 		// token 不存在
// 		// if (token === null || token === '') {
// 		// 	const uri = to.path.split('/');
// 		// 	ElMessage.error('您还没有登录，请先登录');
// 		// 	if (uri[1] === "tool"){
// 		// 		next('/tool');
// 		// 	}else {
// 		// 		next('/login');
// 		// 	}
// 		//
// 		// } else {
// 		// 	next();
// 		// }
// 	}
// });


export default router
