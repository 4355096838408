<template>
    <div class="userCenter">
        <div class="tabs">
            <ul class="tabCard clearfix">
                <li v-for="(item, index) in tabsList" :class="item.activeFlag ? 'itemtab active' : 'itemtab'" @click="changeTabs(item)" :key="index">
                    <el-image :src="item.icon" alt="logo" fit="contain"></el-image>
                    <p class="title">{{item.title}}</p>
                </li>
            </ul>
        </div>

        <router-view />
    </div>
</template>

<script>
import { onBeforeMount, onBeforeUpdate, onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "UserCenter",
    setup() {
        const router = useRouter();
        const store = useStore();

        const data = reactive({
            tabsList: [
                {index: 0, title: '基本信息', icon: require('../assets/img/user_tab1.png'), path: '/userCenter', activeFlag: true},
                {index: 1, title: '签章管理', icon: require('../assets/img/user_tab2.png'), path: '/userCenter/signManage', activeFlag: false},
                {index: 2, title: '我的授权', icon: require('../assets/img/user_tab3.png'), path: '/userCenter/myAuth', activeFlag: false},
                {index: 3, title: '修改密码', icon: require('../assets/img/user_tab4.png'), path: '/userCenter/changePass', activeFlag: false},
                {index: 4, title: '通信秘钥', icon: require('../assets/img/user_tab5.png'), path: '/userCenter/secretKeyAndIv', activeFlag: false}
            ]
        });

        onMounted(()=>{
            initTabs();
        });

        onBeforeUpdate(()=>{
            initTabs();
        });

        // 刷新初始化tab
        const initTabs = ()=>{
            // 切换后刷新
            // if(store.state.userTabsStatus != 0){
                data.tabsList.map((i)=>{
                    i.activeFlag = false;
                });
                data.tabsList[store.state.userTabsStatus].activeFlag = true;
            // }
        }

        // tab切换
        const changeTabs = (item)=>{
            data.tabsList.map((i)=>{
                i.activeFlag = false;
            });
            item.activeFlag = true;
            // 跳转
            router.push(item.path);
            // 缓存
            sessionStorage.setItem('userTabsStatus', item.index);
            store.dispatch('goChangeUserTabs');
            // console.log(store.state.userTabsStatus)
        }

        return {
            ...toRefs(data),
            changeTabs
        };
    },
};
</script>

<style lang="less" scoped>
.userCenter{
    // padding: 0 250px;
    .tabs{
        padding: 0 250px;
        .tabCard{
            .itemtab{
                width: 92px;
                height: 108px;
                float: left;
                margin: 10px 35px;
                cursor: pointer;
                .el-image{
                    padding-top: 17px;
                }
                .title{
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .active{
                background: url('../assets/img/hb.png') 100% 100% no-repeat;
            }
        }
    }

}
</style>
