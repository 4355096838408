<template>
  <div>
    <div class="mation_content">
      <div class="topCol">
        <arrowComp :streamer="data.streamerMsg"></arrowComp>
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">与企业签约的小工具</el-col>
        </el-row>
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">H5</el-col>
          <el-col :span="12"><el-input v-model="data.companyLink" :readonly="true"></el-input></el-col>
          <el-col :span="6"><el-button type="info" @click="copyCode(data.companyLink)" round>复制链接</el-button></el-col>
        </el-row>
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">二维码</el-col>
          <el-col :span="6"><qrcode-vue :value="data.companyLink" :size="data.size" level="H" /></el-col>
        </el-row>
        <el-divider />
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">与个人签约的小工具</el-col>
        </el-row>
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">H5</el-col>
          <el-col :span="12"><el-input v-model="data.personalLink" :readonly="true"></el-input></el-col>
          <el-col :span="6"><el-button type="info" @click="copyCode(data.personalLink)" round>复制链接</el-button></el-col>
        </el-row>
        <br/><br/>
        <el-row :gutter="20">
          <el-col :span="6" class="defalutFrontClass">二维码</el-col>
          <el-col :span="6"><qrcode-vue :value="data.personalLink" :size="data.size" level="H" /></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import arrowComp from "@/components/banner";
import QrcodeVue from 'qrcode.vue'
import {ElMessage} from "element-plus";

export default {
  name: "SmallTool",

  setup(){
    const data = reactive({
      streamerMsg :  "我的小工具",
      companyLink:"",
      personalLink:"",
      size: 150,
    });
    const initFun =()=>{
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      data.companyLink = process.env.VUE_APP_TOOL_URL + "?companyId="+userInfo[0].userId+"&inviteType=10";
      data.personalLink =process.env.VUE_APP_TOOL_URL + "?companyId="+userInfo[0].userId+"&inviteType=20";
    };
    // 复制
    const copyCode = (copytext) => {

      const text = document.createElement('textarea'); // 创建节点
      text.setAttribute('readonly', 'readonly');
      text.value = copytext; // 赋值
      document.body.appendChild(text); // 插入节点
      text.setSelectionRange(0, text.value.length);
      text.select(); // 选中节点
      document.execCommand('copy'); // 执行浏览器复制方法
      if (document.body.removeChild(text)) {
        ElMessage.success('复制成功');
      } else {
        ElMessage.error('复制失败');
      }
    };
    onMounted(()=>{
      initFun();
    });

    return {
      data,
      copyCode
    };
  },
  components:{
    arrowComp,
    QrcodeVue
  },
}
</script>

<style scoped>
.mation_content {
  width: 1200px;
  margin: 0px auto;
  border-top: none;
  overflow: hidden;
  margin-bottom: 74px;
  padding-bottom: 10px;
}
.defalutFrontClass{
  color: #333333;
  font-weight: bold;
}
</style>
