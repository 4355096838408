<template>
    <div class="appCenter">
        <div class="tabs">
            <ul class="tabCard clearfix">
                <li v-for="(item, index) in tabsList" :class="item.activeFlag ? 'itemtab active' : 'itemtab'" @click="changeTabs(item)" :key="index">
                    <el-image :src="item.icon" alt="logo" fit="contain"></el-image>
                    <p class="title">{{item.title}}</p>
                </li>
            </ul>
        </div>
        <router-view />
    </div>
</template>

<script>
    import {  onBeforeUpdate, onMounted, reactive, toRefs } from 'vue';
    import arrowComp from '../components/banner'
    import { useRouter,useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    export default {
        name: "AppCenter",
        components: {
            arrowComp
        },
        setup(){
            const data = reactive({
                showMsg:"我的应用",
                tabsList: [
                    {index: 0, title: '我的应用', icon: require('../assets/img/user_tab1.png'), path: '/appCenter', activeFlag: true}
                ],
            });
            const router = useRouter();
            const store = useStore();
            onMounted(()=>{
                initTabs();
            });

            onBeforeUpdate(()=>{
                initTabs();
            });

            // 刷新初始化tab
            const initTabs = ()=>{
                addSubTabs();
            }

            const addSubTabs = function () {
                data.userInfo =  JSON.parse(sessionStorage.getItem('userInfo'));
                data.tabsList= [],
                data.tabsList.push({index: 0, title: '我的应用', icon: require('../assets/img/user_tab1.png'), path: '/appCenter', activeFlag: true})
                if (data.userInfo[0].companySignToolFlag == 1){
                    data.tabsList.push({index: 1, title: '我的小工具', icon: require('../assets/img/my_tool.png'), path: '/appCenter/smallTool', activeFlag: false})
                    data.tabsList.push({index: 2, title: '工具负责人', icon: require('../assets/img/tool_manager.png'), path: '/appCenter/staff', activeFlag: false})
                    data.tabsList.push({index: 3, title: '乙方个人管理', icon: require('../assets/img/tool_invite_person.png'), path: '/appCenter/individual', activeFlag: false})
                    data.tabsList.push({index: 4, title: '我的模板', icon: require('../assets/img/templete_manage.png'), path: '/appCenter/templateManage', activeFlag: false})
                }
                data.tabsList.map((i)=>{
                    i.activeFlag = false;
                });
                data.tabsList[store.state.userTabsStatus].activeFlag = true;

            }
            // tab切换
            const changeTabs = (item)=>{
                data.tabsList.map((i)=>{
                    i.activeFlag = false;
                });
                item.activeFlag = true;
                // 跳转
                router.push(item.path);
                // 缓存
                sessionStorage.setItem('userTabsStatus', item.index);
                store.dispatch('goChangeUserTabs');
            }

            return {
                ...toRefs(data),
                changeTabs
            };
        }
    }
</script>

<style lang="less" scoped>
    .appCenter{
        // padding: 0 250px;
        .tabs{
            padding: 0 250px;
            .tabCard{
                .itemtab{
                    width: 92px;
                    height: 108px;
                    float: left;
                    margin: 10px 35px;
                    cursor: pointer;
                    .el-image{
                        padding-top: 17px;
                    }
                    .title{
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
                .active{
                    background: url('../assets/img/hb.png') 100% 100% no-repeat;
                }
            }
        }

    }
</style>
