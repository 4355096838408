<template>
    <div class="viewBasicInfo">
        <div class="topCol">
            <arrowComp :streamer="'认证信息'"></arrowComp>
        </div>
        <div class="formCont">
            <el-form :model="formData" ref="ruleFormRef" label-width="auto" class="demo-formData" label-position="right">
                <el-form-item label="" prop="operatorType" style="text-align: left">
                  <template #label>
                    <div>
                      <span style="color: red">* </span>
                      <span>操作人身份：</span>
                    </div>
                  </template>
                  <el-radio-group v-model="formData.operatorType" disabled >
                    <el-radio :label="1">我是法人</el-radio>
                    <el-radio :label="2">我是经办人</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="formData.operatorType == 2">
                  <el-form-item label="" prop="name">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>操作人信息：</span>
                      </div>
                    </template>
                    <el-input v-model="formData.applyerName" readonly></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="phone">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>操作人联系电话：</span>
                      </div>
                    </template>
                    <el-input v-model="formData.applyerMobile" readonly></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="certificate">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>证件类型：</span>
                      </div>
                    </template>
                    <el-select v-model="formData.applyerIdentityType" placeholder="请选择证件类型" style="width: 100%" disabled>
                      <el-option v-for="(item, index) in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="photo" class="uploadImg">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>上传证件照：</span>
                      </div>
                    </template>
                    <div class="sign-uploader" @click="handlePicPreview(formData.applyerPic1)">
                      <img :src="formData.applyerPic1" class="avatar" width="100%">
                    </div>
                    <div class="sign-uploader" style="margin-left: 24px" @click="handlePicPreview(formData.applyerPic2)">
                      <img :src="formData.applyerPic2" class="avatar" width="100%">
                    </div>
                    <!-- <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="applyChange"
                        :disabled="disabledOne"
                        >
                        <img v-if="applyImgUrl" :src="applyImgUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="applyImgUrl" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(applyImgUrl)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_pic1()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="applyChange2"
                        :disabled="disabledTwo"
                        style="margin-left: 24px"
                        >
                        <img v-if="applyImgUrl2" :src="applyImgUrl2" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="applyImgUrl2" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(applyImgUrl2)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_pic2()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload> -->
                  </el-form-item>
                </div>

                <el-form-item label="" prop="name_legal">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人姓名：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalName" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="phone_legal">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人联系电话：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalMobile" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="certificate_legal">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>证件类型：</span>
                        </div>
                    </template>
                    <el-select v-model="formData.legalIdentityType" placeholder="请选择证件类型" style="width: 100%" disabled>
                        <el-option v-for="(item, index) in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="zjNo_legal">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人证件号：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalIdentityNo" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="photo_legal" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传证件照：</span>
                        </div>
                    </template>
                    <div class="sign-uploader" @click="handlePicPreview(formData.legalPic1)">
                        <img :src="formData.legalPic1" class="avatar" width="100%">
                    </div>
                    <div class="sign-uploader" style="margin-left: 24px" @click="handlePicPreview(formData.legalPic2)">
                        <img :src="formData.legalPic2" class="avatar" width="100%">
                    </div>
                    <!-- <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="frChange"
                        :disabled="disabledThree"
                        >
                        <img v-if="FRImgUrl" :src="FRImgUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="FRImgUrl" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(FRImgUrl)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_picFR1()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="frChange2"
                        :disabled="disabledFour"
                        style="margin-left: 24px"
                        >
                        <img v-if="FRImgUrl2" :src="FRImgUrl2" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="FRImgUrl2" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(FRImgUrl2)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_picFR2()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload> -->
                </el-form-item>
                <el-form-item label="" prop="legalThirdCheck">
                  <template #label>
                    <div>
                      <span style="color: red">* </span>
                      <span>法人意愿认证状态：</span>
                    </div>
                  </template>
                  <el-row :gutter="20"  v-if="formData.legalThirdCheck == 0">
                    <el-col :span="24" style="text-align: left"><i class="el-icon-circle-close" style="font-size: medium">暂未通过</i></el-col>
                  </el-row>
                  <el-row :gutter="20"  v-if="formData.legalThirdCheck == 1">
                    <el-col :span="24" style="text-align: left"><i class="el-icon-circle-check" style="color: green;font-size: medium">已通过</i></el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="" prop="company">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>企业名称：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.userName" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="address">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>实际经营地址：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.addressBus" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="email">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>认证邮箱：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.email" readonly></el-input>
                </el-form-item>
                <el-form-item label="" style="text-align: left">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>证件类型：</span>
                        </div>
                    </template>
                    <span>三证合一</span>
                </el-form-item>
                <el-form-item label="" prop="xyCode">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>统一社会信用代码：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.tyshxydmNo" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="zhizhao" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传营业执照副本：</span>
                        </div>
                    </template>
                    <div class="sign-uploader" @click="handlePicPreview(formData.tyshxydmPic1)">
                        <img :src="formData.tyshxydmPic1" class="avatar" width="100%">
                    </div>
                    <!-- <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="authChange"
                        :disabled="disabledFive"
                        >
                        <img v-if="zjImgUrl" :src="zjImgUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="zjImgUrl" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(zjImgUrl)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_yyzz()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload> -->
                </el-form-item>
                <el-form-item label="" prop="zhengshu" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传诺曼签约服务授权书：</span>
                        </div>
                    </template>
                    <div class="sign-uploader" @click="handlePicPreview(formData.authPic)">
                        <img :src="formData.authPic" class="avatar" width="100%">
                    </div>
                    <!-- <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="authChange2"
                        :disabled="disabledSix"
                        >
                        <img v-if="zjImgUrl2" :src="zjImgUrl2" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <span v-if="zjImgUrl2" class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-preview" @click="handlePicPreview(zjImgUrl2)">
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span class="el-upload-list__item-delete" @click="remove_yyzz()">
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </el-upload> -->
                </el-form-item>

                <el-form-item>
                    <div class="tips">
                        请将下载的《诺曼签约服务授权书》模板打印填写完整后，签名并加盖印章，拍照或扫描上传。
                        <span><a :href="useAxios.defaults.baseURL + '/tpl/诺曼签约服务授权书.doc'">下载模板</a></span>
                    </div>
                    <!-- <el-button type="primary" @click="submitForm" style="width: 100%">保存</el-button> -->
                </el-form-item>
            </el-form>

            <el-dialog v-model="dialogVisible">
                <el-image :src="dialogImageUrl" alt="logo" fit="cover"></el-image>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs,onBeforeMount } from 'vue'
import arrowComp from '../components/banner'
import companyAuthed from '../components/companyAuthed'
import { ElMessage  } from "element-plus";
import { useRoute, useRouter } from 'vue-router';

export default {
    name: "ViewBasicInfo",
    components: {
        arrowComp,
        companyAuthed
    },
    setup(){
        const useAxios = inject('useAxios');
        const router = useRouter();
        const route = useRoute();
        // console.log(route.params)

        const data = reactive({
            formData: {},
            // 弹出框
            dialogImageUrl: '',
            dialogVisible: false,

            options: [{
                value: '0',
                label: '居民身份证'
            }, {
                value: '1',
                label: '护照'
            }, {
                value: '2',
                label: '军人身份证'
            }, {
                value: '5',
                label: '股东代码证'
            }, {
                value: '6',
                label: '社会保障卡'
            },{
                value: '9',
                label: '法人代码证'
            }, {
                value: 'A',
                label: '武装警察身份证件'
            }, {
                value: 'B',
                label: '港澳居民往来内地通行证'
            }, {
                value: 'C',
                label: '台湾居民来往大陆通行证'
            }, {
                value: 'E',
                label: '户口簿'
            },{
                value: 'F',
                label: '临时居民身份证'
            }, {
                value: 'G',
                label: '警察(警官)证'
            }, {
                value: 'P',
                label: '外国人永久居留证'
            }, {
                value: 'Z',
                label: '其他'
            }],
        });

        onMounted(()=>{

        });

        onBeforeMount(()=>{
          getCompanyInfo();
        });

        // 已认证公司信息
        const getCompanyInfo = () => {
          useAxios.get("/foreground/web/company/auth/detail").then((res) => {
            if(res.data.code == "0"){
              data.formData = res.data.attach;
            }
          }).catch((err) => {});
        }

        // 预览
        let handlePicPreview = (file) => {
            data.dialogVisible = true;
            data.dialogImageUrl = file;
        }

        return {
            useAxios,
            ...toRefs(data),
            handlePicPreview
        }
    }
}
</script>

<style lang="less" scoped>
.viewBasicInfo{
    padding: 0 250px;
    .topCol{
        width: 100%;
        overflow: hidden;
    }
    .formCont{
        padding: 30px 130px;
        .step{
            color: green;
            font-weight: bold;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            & > p{
                float: left;
            }
            .arr{
                display: block;
                float: left;
                font-size: 40px;
                margin: 0px 20px 0px 20px;
                line-height: 25px;
            }
        }
        .uploadImg{
            /deep/ .el-form-item__content{
                display: flex;
            }
        }
        .tips{
            font-size: 12px;
            color: #A3A4A8;
            line-height: 20px;
            padding: 15px 0;
            & > span a{
                color: #0078FF;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        // 上传相关
        // /deep/.sign-uploader .el-upload {
        //     width: 150px;
        //     height: 150px;
        //     line-height: 150px;
        //     text-align: center;
        //     background-color: #fbfdff;
        //     border: 1px dashed #c0ccda;
        //     border-radius: 6px;
        //     box-sizing: border-box;
        //     cursor: pointer;
        //     position: relative;
        //     overflow: hidden;
        //     .el-upload-list__item-actions{
        //         position: absolute;
        //         width: 100%;
        //         height: 100%;
        //         left: 0;
        //         top: 0;
        //         cursor: default;
        //         text-align: center;
        //         color: #fff;
        //         opacity: 0;
        //         font-size: 20px;
        //         background-color: rgba(0,0,0,.5);
        //         transition: opacity .3s;
        //         .el-upload-list__item-preview{
        //             position: static;
        //             font-size: 20px;
        //         }
        //         .el-upload-list__item-delete {
        //             position: static;
        //             font-size: 20px;
        //             color: inherit;
        //             margin-left: 15px;
        //             display: inline-block;
        //             .el-icon-delete{
        //                 font-family: element-icons!important;
        //                 speak: none;
        //                 font-style: normal;
        //                 font-weight: 400;
        //                 font-variant: normal;
        //                 text-transform: none;
        //                 line-height: 1;
        //                 vertical-align: baseline;
        //                 display: inline-block;
        //                 -webkit-font-smoothing: antialiased;
        //             }
        //         }
        //     }
        //     .el-upload-list__item-actions:hover{
        //         opacity: 1;
        //     }
        //     .el-upload-list__item {
        //         transition: all .5s cubic-bezier(.55,0,.1,1);
        //         font-size: 14px;
        //         color: #606266;
        //         line-height: 1.8;
        //         margin-top: 5px;
        //         position: relative;
        //         box-sizing: border-box;
        //         border-radius: 4px;
        //         width: 100%;
        //     }
        // }
        // /deep/.sign-uploader .el-upload:hover {
        //     border-color: #409EFF;
        // }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
        }
        .avatar {
            width: 150px;
            height: 150px;
            display: block;
        }

        // 预览
        .sign-uploader{
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
            background-color: #fbfdff;
            border: 1px dashed #c0ccda;
            border-radius: 6px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
    }
}
/deep/ .el-radio__input.is-disabled+span.el-radio__label {
  color: #606266;
}
/deep/ .el-radio__input.is-checked .el-radio__inner{
  background-color: #409EFF !important;
  border-color: #409EFF !important;
}
/deep/ .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
   background-color: #ffffff;
}
</style>
