<template>
    <div class="header">
        <div>
            <el-row>
                <el-col :xs="5" :sm="5" :md="5" :lg="5">
                    <img src="../assets/img/logo.png" class="logo" height="55" alt="logo">
                </el-col>
                <el-col :xs="14" :sm="14" :md="14" :lg="14">
                    <el-tabs v-model="activeName" @tab-click="changeTabs" :stretch="false">
                        <el-tab-pane label="首页" name="first"></el-tab-pane>
                        <el-tab-pane label="用户中心" name="second"></el-tab-pane>
                        <el-tab-pane label="高级应用" name="third"></el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :xs="5" :sm="5" :md="5" :lg="5">
                    <div class="userInfo">
                        <!-- <p>你好，万科置业有限责任大大的公司</p> -->
                        <el-dropdown @command="handleCommand">
                          <p class="el-dropdown-link">
                            {{initialize.name}} {{initialize.accountNo}} {{initialize.userType == 1 ? '个人账号':'企业账号'}}
                          </p>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item v-for="(info,index) in userInfo" :key="index" :disabled="initialize.userId == info.userId" :command="info">
                                {{info.name}}  {{info.accountNo}} （{{info.userType == 1 ? '个人账号':'企业账号'}}）
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                        <img src="../assets/img/exit.png" height="19" class="exit" @click="quit">
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, reactive, toRefs, inject, watch, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessageBox } from "element-plus";

export default {
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const useAxios = inject("useAxios");

        const data = reactive({
            activeName: sessionStorage.getItem('mainTabsStatus') ? sessionStorage.getItem('mainTabsStatus') : 'first',
            // activeName: store.state.mainTabsStatus ? store.state.mainTabsStatus : 'first',
            userInfo: [],
            initialize: '',
        });

        watch(()=>route.path, (newValue, oldValue)=>{
            // console.log(newValue)
            if(newValue == '/userCenter'){
                data.activeName = 'second';
            }
            if(newValue == '/AppCenter'){
                data.activeName = 'third';
            }
        });

        onBeforeMount(()=>{
            // if(sessionStorage.getItem('mainTabsStatus')){
            //     data.activeName = sessionStorage.getItem('mainTabsStatus');
            // }

            //获取用户信息
            if(sessionStorage.getItem('userInfo')){
                data.userInfo =  JSON.parse(sessionStorage.getItem('userInfo'));
                data.initialize = data.userInfo[0];
            }
        });

        const changeTabs = (tab, event) => {
            // console.log(tab.props);
            switch(tab.props.name){
                case 'first':
                    router.push('/home')
                    break
                case 'second':
                    router.push('/userCenter')
                    sessionStorage.setItem('userTabsStatus', 0);
                    store.dispatch('goChangeUserTabs');
                    break
                case 'third':
                    router.push('/appCenter')
                    sessionStorage.setItem('userTabsStatus', 0);
                    store.dispatch('goChangeUserTabs');
                    break
                default:
                    return
            };
            // 缓存
            sessionStorage.setItem('mainTabsStatus', tab.props.name);
        };

        function handleCommand (info){
            data.initialize = info
        };

        //退出登录
        function quit(){
          ElMessageBox.confirm('你确定要退出登录吗?', '提示', {
            confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              useAxios.post('/foreground/web/logout').then(res=>{
                if(res.data.code  == 0){
                  window.sessionStorage.clear();
                  window.location.replace('/#/login');
                }
              })
            });
        }

        return {
            ...toRefs(data),
            changeTabs,
            handleCommand,
            quit
        }
    }
}
</script>

<style lang="less" scoped>
    .header{
        width: 100%;
        height: 100px;
        background: url(../assets/img/head_bg.jpg) 0px top no-repeat;
        position: fixed;
        top: 0;
        z-index: 999;
        .logo{
            vertical-align: middle;
            margin-top: 22px;
        }
        .el-tabs{
            padding-left: 50px;
            padding-right: 20px;
            color: #303133;
            font-weight: bold;
            font-size: 16px;
            line-height: 100px;
            /deep/ .el-tabs__nav-wrap::after{
                height: 0 !important;
            }
            /deep/ .el-tabs__active-bar{
                height: 0;
            }
            /deep/ .el-tabs__item{
                font-weight: bold;
                font-size: 16px;
                line-height: 100px;
            }
        }
        .userInfo{
            display: flex;
            transform: translateY(39px);
            .el-dropdown-link{
                color: #303133;
                font-weight: bold;
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 250px;
                cursor:pointer
            }
            .exit{
                padding-left: 12px;
                padding-right: 60px;
                cursor: pointer;
            }
        }
    }
</style>
