<template>
  <div>
    <div id="j_left">
      <div class="mation_left">
        <span class="wd" id="wd151">
          <img class="wd_img" src="../assets/img/wd_1.png" />
          <p id="solo_p151">我的应用</p>
        </span>
      </div>
    </div>
    <div class="wd_bg">{{streamer}}</div>
  </div>
</template>

<script>
export default {
  name:'application',
  props:{
    streamer:String
  }
}
</script>

<style lang="less" scoped>
#j_left {
  width: 100%;
  height: 115px;
  position: relative;
  .mation_left {
    width: 100%;
    height: 115px;
    z-index: 999999999;
    span {
      width: 14.2%;
      height: 108px;
      display: block;
      float: left;
      margin-top: 5px;
      color: #000;
      padding-top: 17px;
      background: url("../assets/img/hb.png") center top no-repeat;
      cursor: pointer;
      img {
        display: block;
        width: 48px;
        height: 43px;
        margin: auto;
      }
      p {
        display: block;
        width: 100px;
        height: 30px;
        margin: auto;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

.wd_bg {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 70px;
  margin-top: 10px;
  background: url(../assets/img/img1.png) 0px top no-repeat;
  margin-top: 30px;
  text-align: left;
}
</style>