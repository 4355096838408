<template>
    <div class="updateBasicInfo">
        <div class="topCol">
            <arrowComp :streamer="'认证信息'"></arrowComp>
        </div>
        <div class="formCont">
            <el-form :model="formData" label-width="auto" class="demo-formData" label-position="right">
                <el-form-item label="" prop="operatorType" style="text-align: left">
                  <template #label>
                    <div>
                      <span style="color: red">* </span>
                      <span>操作人身份：</span>
                    </div>
                  </template>
                  <el-radio-group v-model="formData.operatorType">
                    <el-radio :label="1">我是法人</el-radio>
                    <el-radio :label="2">我是经办人</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="formData.operatorType == 2">
                  <el-form-item label="" prop="name">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>操作人信息：</span>
                      </div>
                    </template>
                    <el-input v-model="formData.applyerName"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="phone">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>操作人联系电话：</span>
                      </div>
                    </template>
                    <el-input v-model="formData.applyerMobile"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="applyerIdentityType">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>证件类型：</span>
                      </div>
                    </template>
                    <el-select v-model="formData.applyerIdentityType" placeholder="请选择证件类型" style="width: 100%">
                      <el-option v-for="(item, index) in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="photo" class="uploadImg">
                    <template #label>
                      <div>
                        <span style="color: red">* </span>
                        <span>上传证件照：</span>
                      </div>
                    </template>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="applyChange"
                    >
                      <img v-if="formData.applyerPic1" :src="formData.applyerPic1" class="avatar">
                    </el-upload>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="applyChange2"
                        style="margin-left: 24px"
                    >
                      <img v-if="formData.applyerPic2" :src="formData.applyerPic2" class="avatar">
                    </el-upload>
                  </el-form-item>
                </div>
                <el-form-item label="" prop="legalName">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人姓名：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalName" :disabled="formData.frThirdCheck == 1"></el-input>
                </el-form-item>
                <el-form-item label="" prop="legalMobile">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人联系电话：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalMobile" :disabled="formData.frThirdCheck == 1"></el-input>
                    <el-row>
                      <span style="color: red">需持法人身份证办理的手机号，法人意愿认证时使用</span>
                    </el-row>
                </el-form-item>
                <el-form-item label="" prop="legalIdentityType">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>证件类型：</span>
                        </div>
                    </template>
                    <el-select v-model="formData.legalIdentityType" placeholder="请选择证件类型" style="width: 100%" >
                        <el-option v-for="(item, index) in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="legalIdentityNo">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>法人证件号：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.legalIdentityNo" :disabled="formData.frThirdCheck == 1"></el-input>
                </el-form-item>
                <el-form-item label="" prop="photo_legal" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传证件照：</span>
                        </div>
                    </template>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="frChange"
                        >
                        <img v-if="formData.legalPic1" :src="formData.legalPic1" class="avatar">
                    </el-upload>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="frChange2"
                        style="margin-left: 24px"
                        >
                        <img v-if="formData.legalPic2" :src="formData.legalPic2" class="avatar">
                    </el-upload>
                </el-form-item>
                <el-form-item label="" prop="legal_phone_code">
                  <template #label>
                    <div>
                      <span style="color: red">* </span>
                      <span>法人意愿认证：</span>
                    </div>
                  </template>
                  <el-row :gutter="20">
                    <el-col :span="8"> <el-input v-model="formData.legalPhoneCode"></el-input></el-col>
                    <el-col :span="8"><el-button type="primary" @click="getPhoneCode" :disabled="phoneCodeDisabled" style="width: 100%">{{ phoneCodeText }}</el-button></el-col>
                    <el-col :span="8"><el-button type="primary" @click="submitLegalAuth" style="width: 100%" v-if="formData.frThirdCheck == 0 && legalAuthShowFlag">提交认证</el-button></el-col>
                  </el-row>
                  <el-row>
                    <span style="color: red">请输入法人手机号接收的短信验证码</span>
                  </el-row>
                </el-form-item>
                <el-form-item label="" prop="frThirdCheck">
                  <template #label>
                    <div>
                      <span style="color: red">* </span>
                      <span>法人意愿认证状态：</span>
                    </div>
                  </template>
                  <el-row :gutter="20"  v-if="formData.frThirdCheck == 0">
                    <el-col :span="24" style="text-align: left"><i class="el-icon-circle-close" style="font-size: medium">暂未通过</i></el-col>
                  </el-row>
                  <el-row :gutter="20"  v-if="formData.frThirdCheck == 1">
                    <el-col :span="8" style="text-align: left">
                      <i class="el-icon-circle-check" style="color: green;font-size: medium">已通过</i>
                    </el-col>
                    <el-col :span="8" style="text-align: left">
                      <el-button type="primary" @click="redoAuth" style="width: 100%">撤销并重新认证</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item label="" prop="userName">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>企业名称：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.userName" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="addressBusBus">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>实际经营地址：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.addressBus"></el-input>
                </el-form-item>
                <el-form-item label="" prop="email">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>认证邮箱：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.email" readonly></el-input>
                </el-form-item>
                <el-form-item label="" style="text-align: left">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>证件类型：</span>
                        </div>
                    </template>
                    <span>三证合一</span>
                </el-form-item>
                <el-form-item label="" prop="tyshxydmNo">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>统一社会信用代码：</span>
                        </div>
                    </template>
                    <el-input v-model="formData.tyshxydmNo" readonly></el-input>
                </el-form-item>
                <el-form-item label="" prop="tyshxydmPic1" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传营业执照副本：</span>
                        </div>
                    </template>
                    <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="authChange"
                        >
                        <img v-if="formData.tyshxydmPic1" :src="formData.tyshxydmPic1" class="avatar">
                    </el-upload>
                </el-form-item>
                <el-form-item label="" prop="authPic" class="uploadImg">
                    <template #label>
                        <div>
                            <span style="color: red">* </span>
                            <span>上传诺曼签约服务授权书：</span>
                        </div>
                    </template>
                     <el-upload
                        class="sign-uploader"
                        action=""
                        :multiple="false"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="authChange2"
                        >
                        <img v-if="formData.authPic" :src="formData.authPic" class="avatar">
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <div class="tips">
                        请将下载的《诺曼签约服务授权书》模板打印填写完整后，签名并加盖印章，拍照或扫描上传。
                        <span><a :href="useAxios.defaults.baseURL + '/tpl/诺曼签约服务授权书.doc'">下载模板</a></span>
                    </div>
                    <el-row  :gutter="20">
                      <el-col :span="12"><el-button type="primary" @click="saveForm" style="width: 100%">暂存</el-button></el-col>
                      <el-col :span="12"><el-button type="primary" @click="submitForm" style="width: 100%">提交</el-button></el-col>
                    </el-row>
                </el-form-item>
            </el-form>

            <el-dialog v-model="dialogVisible">
                <el-image :src="formData.dialogImageUrl" alt="logo" fit="cover"></el-image>
            </el-dialog>
        </div>
    </div>
    <div>
      <el-dialog title="图形验证码验证" v-model="pictureFormVisible" :close-on-click-modal="false" width="30%">
        <el-form :model="pictureForm" :rules="pictureFormRules" ref="pictureFormRef">
          <el-form-item label="图形验证码" prop="pictureCode">
            <el-row :gutter="20">
              <el-col :span="12"><el-input v-model="pictureForm.pictureCode" placeholder="请输入图形验证码"></el-input></el-col>
              <el-col :span="12" style="text-align: left"><img :src="pictureForm.pictureUrl" @click="getPictureCode"></el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="pictureFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="pictureFormSubmit">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { inject, onMounted,  reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import arrowComp from '../components/banner'
import companyAuthed from '../components/companyAuthed'
import { useRoute, useRouter } from 'vue-router';

export default {
    name: "UpdateBasicInfo",
    components: {
        arrowComp,
        companyAuthed
    },

    setup(){
        const useAxios = inject('useAxios');
        const route = useRoute();
        const router = useRouter();
        const pictureFormRef = ref();

        const pictureFormRules = {
          pictureCode: [
            { required: true, message: '请填写图形验证码', trigger: 'blur' }
          ],
        };

        const data = reactive({
            formData: new Object(),
            // 是否认证
            authShow: false,
            // 弹出框
            dialogVisible: false,
            picData: {
                applyerPic1: '',
                applyerPic2: '',
                legalPic1: '',
                legalPic2: '',
                tyshxydmPic1: '',
                authPic: ''
            },

            options: [{
                value: '0',
                label: '居民身份证'
            }, {
                value: '1',
                label: '护照'
            }, {
                value: '2',
                label: '军人身份证'
            }, {
                value: '5',
                label: '股东代码证'
            }, {
                value: '6',
                label: '社会保障卡'
            },{
                value: '9',
                label: '法人代码证'
            }, {
                value: 'A',
                label: '武装警察身份证件'
            }, {
                value: 'B',
                label: '港澳居民往来内地通行证'
            }, {
                value: 'C',
                label: '台湾居民来往大陆通行证'
            }, {
                value: 'E',
                label: '户口簿'
            },{
                value: 'F',
                label: '临时居民身份证'
            }, {
                value: 'G',
                label: '警察(警官)证'
            }, {
                value: 'P',
                label: '外国人永久居留证'
            }, {
                value: 'Z',
                label: '其他'
            }],
            rules: {},
            // 图形验证码 弹窗标识
            pictureFormVisible: false,
            // 图形验证码表单
            pictureForm: {
              pictureCode: '',
              pictureUrl: ''
            },
            // 发送短信验证码按钮是否可以点击
            phoneCodeDisabled: false,
            phoneCodeText: '获取验证码',
            // 获取验证码校验结果
            checkPhoneCodeRes: false,
            // 法人提价认证标识
            legalAuthShowFlag: false
        });
        onMounted(()=>{
            seeInfo(route.params.userId);
        });
        // base64
        let getBase64 = (file) => {
            return new Promise(function(resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function() {
                    imgResult = reader.result;
                };
                reader.onerror = function(error) {
                    reject(error);
                };
                reader.onloadend = function() {
                    resolve(imgResult);
                };
            });
        }
        // 图片校验
        let uploadImgsValidate = (file) => {
            const isJPG = file.raw.type === 'image/jpg';
            const isJPEG = file.raw.type === 'image/jpeg';
            const isPNG = file.raw.type === 'image/png';
            const isLt10M = file.raw.size / 1024 / 1024 < 10;

            if (!isJPG && !isJPEG && !isPNG) {
                ElMessage.error('只能上传 jpg/png 图片!');
                return false
            }
            if (!isLt10M) {
                ElMessage.error('上传图片大小不能超过 10MB!');
                return false
            }

            return true
        }
        // 上传
        let uploadImgs = (imgurl, type) => {
            return new Promise((resolve, reject) => {
                useAxios.post("/foreground/web/company/upload/img", {img: imgurl, imgKind: type}).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                });
            });
        }
        // 上传证件照1   ============ >
        let applyChange = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.applyerPic1 = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 1).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.applyerPic1 = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 上传证件照2   ============ >
        let applyChange2 = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.applyerPic2 = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 1).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.applyerPic2 = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 上传法人证件照1   ============ >
        let frChange = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.legalPic1 = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 1).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.legalPic1 = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 上传法人证件照2   ============ >
        let frChange2 = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.legalPic2 = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 1).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.legalPic2 = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 上传营业执照副本   ============ >
        let authChange = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.tyshxydmPic1 = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 2).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.tyshxydmPic1 = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 上传诺曼签约服务证书   ============ >
        let authChange2 = (file, fileList) => {
            if(!uploadImgsValidate(file)){
                return
            }
            let temUrl;
            getBase64(file.raw).then(res => {
                data.formData.authPic = res;
                temUrl = res.split(',')[1];
                uploadImgs(temUrl, 3).then(res=>{
                    if(res.data.code == "0"){
                        if(res.data.attach.imgName){
                            data.picData.authPic = res.data.attach.imgName;
                        }
                    }
                });
            });
        }
        // 表单提交
        const submitForm = () => {
            // 手机校验
            let telPass = new RegExp('^[1][3-9][0-9]{9}$');
            let applytelVal = telPass.test(data.formData.applyerMobile);
            let frtelVal = telPass.test(data.formData.legalMobile);
            // 邮箱
            const emailPass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
            const rzemail = emailPass.test(data.formData.email);

            // 如果是经办人
            if (data.formData.operatorType == 2) {
              if(data.formData.applyerName.trim() == ''){
                ElMessage.error('请填写申请人姓名');
                return
              }
              if(data.formData.applyerMobile.trim() == ''){
                ElMessage.error('请填写申请人联系电话');
                return
              }
              if(!applytelVal){
                ElMessage.error('请填写正确的申请人联系电话');
                return
              }
              if(data.picData.applyerPic1 == ''){
                ElMessage.error('请上传申请人证件信息正面');
                return
              }
              if(data.picData.applyerPic2 == ''){
                ElMessage.error('请上传申请人证件信息背面');
                return
              }
            }

            if(data.formData.legalName.trim() == ''){
                ElMessage.error('请填写法人姓名');
                return
            }
            if(data.formData.legalMobile.trim() == ''){
                ElMessage.error('请填写法人联系电话');
                return
            }
            if(!frtelVal){
                ElMessage.error('请填写正确的法人联系电话');
                return
            }
            if(data.formData.legalIdentityNo.trim() == ''){
                ElMessage.error('请填写法人证件号');
                return
            }
            if(data.picData.legalPic1 == ''){
                ElMessage.error('请上传法人证件信息正面');
                return
            }
            if(data.picData.legalPic2 == ''){
                ElMessage.error('请上传法人证件信息背面');
                return
            }
            if(data.formData.userName.trim() == ''){
                ElMessage.error('请填写企业名称');
                return
            }
            if(data.formData.addressBus.trim() == ''){
                ElMessage.error('请填写实际经营地址');
                return
            }
            if(data.formData.email.trim() == ''){
                ElMessage.error('请填写认证邮箱');
                return
            }
            if(!rzemail){
                ElMessage.error('认证邮箱格式不正确');
                return
            }
            if(data.formData.tyshxydmNo.trim() == ''){
                ElMessage.error('请填写统一社会信用代码');
                return
            }
            if(data.picData.tyshxydmPic1 == ''){
                ElMessage.error('请上传营业执照副本');
                return
            }
            if(data.picData.authPic == ''){
                ElMessage.error('请上传诺曼签约服务证书');
                return
            }
            // 企业未认证 则不允许提交
            if(data.formData.frThirdCheck == 0) {
              ElMessage.error('请完成法人意愿认证');
              return
            }
            submitData();
        }
        const submitData = () => {
            let obj = {
                "addressBus": data.formData.addressBus,  // 实际经营地址
                "applyerIdentityType": data.formData.applyerIdentityType,  // 申请人证件类型
                "applyerMobile": data.formData.applyerMobile,  // 申请人电话
                "applyerName": data.formData.applyerName,  // 申请人姓名
                "applyerPic1": data.picData.applyerPic1,  // 申请人证件正面
                "applyerPic2": data.picData.applyerPic2,  // 申请人证件反面
                "authPic": data.picData.authPic,  // 企业授权书
                "email": data.formData.email,  // 企业认证邮箱
                "legalIdentityNo": data.formData.legalIdentityNo,  // 法人证件号
                "legalIdentityType": data.formData.legalIdentityType,  // 法人证件类型
                "legalMobile": data.formData.legalMobile,  // 法人电话
                "legalName": data.formData.legalName,  // 法人姓名
                "legalPic1": data.picData.legalPic1,  // 法人证件正面
                "legalPic2": data.picData.legalPic2,  // 法人证件反面
                "tyshxydmNo": data.formData.tyshxydmNo,  // 统一社会信用代码
                "tyshxydmPic1": data.picData.tyshxydmPic1,  // 统一社会信用代码副本照片
                "userName": data.formData.userName,  // 企业名称
                // 操作人身份
                "operatorType": data.formData.operatorType
            }
            // 如果申请人类型为法人 则置空申请人填写字段
            if (data.formData.operatorType == 1) {
              obj.applyerIdentityType = '0';
              obj.applyerMobile = '';
              obj.applyerName = '';
              obj.applyerPic1 = '';
              obj.applyerPic2 = '';
            }
            useAxios.post("/foreground/web/company/auth/updateInfo", obj).then((res) => {
                if(res.data.code == "0"){
                    ElMessage.success('企业认证更新成功!');
                    router.push({
                      name: 'Home',
                    });
                }
            }).catch((err) => {});
        }

        // 暂存表单提交
        const saveForm = () => {
          let obj = {
            "addressBus": data.formData.addressBus,  // 实际经营地址
            "applyerIdentityType": data.formData.applyerIdentityType,  // 申请人证件类型
            "applyerMobile": data.formData.applyerMobile,  // 申请人电话
            "applyerName": data.formData.applyerName,  // 申请人姓名
            "applyerPic1": data.picData.applyerPic1,  // 申请人证件正面
            "applyerPic2": data.picData.applyerPic2,  // 申请人证件反面
            "authPic": data.picData.authPic,  // 企业授权书
            "email": data.formData.email,  // 企业认证邮箱
            "legalIdentityNo": data.formData.legalIdentityNo,  // 法人证件号
            "legalIdentityType": data.formData.legalIdentityType,  // 法人证件类型
            "legalMobile": data.formData.legalMobile,  // 法人电话
            "legalName": data.formData.legalName,  // 法人姓名
            "legalPic1": data.picData.legalPic1,  // 法人证件正面
            "legalPic2": data.picData.legalPic2,  // 法人证件反面
            "tyshxydmNo": data.formData.tyshxydmNo,  // 统一社会信用代码
            "tyshxydmPic1": data.picData.tyshxydmPic1,  // 统一社会信用代码副本照片
            "userName": data.formData.userName,  // 企业名称
            // 操作人身份
            "operatorType": data.formData.operatorType,
            // 法人意愿认证状态
            "legalThirdCheck": data.formData.frThirdCheck
          }
          // 如果申请人类型为法人 则置空申请人填写字段
          if (data.formData.operatorType == 1) {
            obj.applyerIdentityType = '0';
            obj.applyerMobile = '';
            obj.applyerName = '';
            obj.applyerPic1 = '';
            obj.applyerPic2 = '';
          }
          // 判断obj对象中是否存在有值属性 至少填写一项才允许暂存
          let flag = false;
          for (let key in obj) {
            // 申请人证件类型 法人证件类型 存在默认值不算填写 故跳过
            if (key === 'applyerIdentityType' || key === 'legalIdentityType' || key === 'legalThirdCheck') {
              continue;
            }
            if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
              flag = true;
              break;
            }
          }
          if (!flag) {
            ElMessage.error('请填写至少一项信息');
            return;
          }
          // 手机校验
          let telPass = new RegExp('^[1][3-9][0-9]{9}$');
          if (data.formData.applyerMobile && !telPass.test(data.formData.applyerMobile)) {
            ElMessage.error('请填写正确的申请人联系电话');
            return;
          }
          if (data.formData.legalMobile && !telPass.test(data.formData.legalMobile)) {
            ElMessage.error('请填写正确的法人联系电话');
            return;
          }
          // 邮箱
          const emailPass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
          if (data.formData.email && !emailPass.test(data.formData.email)) {
            ElMessage.error('认证邮箱格式不正确');
            return;
          }
          // 调用暂存接口
          useAxios.post("/foreground/web/company/draftsCompanyAuthInfo", obj).then((res) => {
            if(res.data.code == "0"){
              ElMessage.success('企业认证暂存成功!');
              router.push({
                name: 'Home',
              });
            }
          }).catch((err) => {});
        }

        // 查看
        const seeInfo = (userId) => {
            useAxios.get("/foreground/web/company/auth/detailForUpdate").then((res) => {
                if(res.data.code == "0"){
                    data.authShow = true;
                    sessionStorage.setItem('authStatus', 2);
                    // sessionStorage.setItem('userInfo', JSON.stringify(res.data.attach));
                    data.formData = res.data.attach;
                    data.picData.applyerPic1 = data.formData.applyerPic1Name;
                    data.picData.applyerPic2 = data.formData.applyerPic2Name;
                    data.picData.authPic = data.formData.authPicName;
                    data.picData.legalPic1 = data.formData.legalPic1Name;
                    data.picData.legalPic2 = data.formData.legalPic2Name;
                    data.picData.tyshxydmPic1 = data.formData.tyshxydmPic1Name;
                    // 取后台返回值
                    data.formData.frThirdCheck = res.data.attach.legalThirdCheck;
                    // 短信验证码重置
                    data.formData.legalPhoneCode = '';
                }
            }).catch((err) => {});
        }

        // 重置获取验证码表单
        const resetPictureForm = () => {
          if (pictureFormRef.value) {
            pictureFormRef.value.resetFields();
          }
        };
        // 校验获取验证码必填字段
        function checkPhoneCodeRule () {
          // 手机校验
          let telPass = new RegExp('^[1][3-9][0-9]{9}$');
          let frtelVal = telPass.test(data.formData.legalMobile);
          if(data.formData.legalName.trim() == ''){
            ElMessage.error('请填写法人姓名');
            return false;
          }
          if(data.formData.legalMobile.trim() == ''){
            ElMessage.error('请填写法人联系电话');
            return false;
          }
          if(!frtelVal){
            ElMessage.error('请填写正确的法人联系电话');
            return false;
          }
          if(data.formData.legalIdentityNo.trim() == ''){
            ElMessage.error('请填写法人证件号');
            return false;
          }
          return true;
        };
        // 获取短信验证码
        const getPhoneCode = () => {
          // 如果已认证 则不允许获取短信验证码
          if (data.formData.frThirdCheck == 1) {
            ElMessage.error('已完成企业意愿认证，无需再获取验证码');
            return;
          }
          // 校验获取验证码必填
          data.checkPhoneCodeRes = checkPhoneCodeRule();
          if (data.checkPhoneCodeRes) {
            resetPictureForm();
            data.pictureFormVisible = true;
            // 先重置 再获取
            data.pictureForm.pictureUrl = null;
            getPictureCode();
          }
        }
        // 获取图形验证码
        const getPictureCode = () => {
          data.pictureForm.pictureCode = '';
          // 获取图形验证码
          useAxios.get("/foreground/web/company/image/code").then((res) => {
            if(res.data.code == "0"){
              data.pictureForm.pictureUrl = res.data.attach.picBase64;
            }
          })
        }
        // 图形验证码提交
        const pictureFormSubmit = () => {
          pictureFormRef.value.validate((valid) => {
            if (valid) {
              sendSms(data.pictureForm.pictureCode);
            } else {
              return false;
            }
          });
        }
        // 发送短信验证码
        function sendSms (imageCode) {
          // 重置法人三要素提交认证按钮显示标识
          data.legalAuthShowFlag = false;
          const sendSmsParam = {};
          sendSmsParam.name = data.formData.legalName;
          sendSmsParam.idNum = data.formData.legalIdentityNo;
          sendSmsParam.mobile =  data.formData.legalMobile;
          sendSmsParam.captcha =  imageCode;
          useAxios.post("/foreground/web/company/sendSmsCode", sendSmsParam).then((res) => {
            if(res.data.code == "0"){
              // 关闭图形验证码dialog
              data.pictureFormVisible = false;
              // 法人提价认证标识
              data.legalAuthShowFlag = true
              // 发送短信验证码成功 开启倒计时
              data.phoneCodeDisabled = true;
              data.phoneCodeText = 60;
              let timing = setInterval(() => {
                data.phoneCodeText --;
                if(data.phoneCodeText == 0){
                  data.phoneCodeDisabled = false;
                  data.phoneCodeText = '获取验证码'
                  clearInterval(timing);
                }
              }, 1000);
            }
          }).catch((err) => {
          });
        }
        // 法人三要素提交认证
        const submitLegalAuth = () => {
          // 校验获取验证码必填
          data.checkPhoneCodeRes = checkPhoneCodeRule();
          // 校验三要素必填未通过
          if (!data.checkPhoneCodeRes) {
            return ;
          }
          if(data.formData.legalPhoneCode.trim() == ''){
            ElMessage.error('请填写法人意愿认证');
            return
          }
          const sendSmsParam = {};
          sendSmsParam.name = data.formData.legalName;
          sendSmsParam.idNum = data.formData.legalIdentityNo;
          sendSmsParam.mobile =  data.formData.legalMobile;
          sendSmsParam.captcha =  data.formData.legalPhoneCode;
          useAxios.post("/foreground/web/company/submitCompanyAuthCarriersAuth", sendSmsParam).then((res) => {
            if(res.data.code == "0"){
              // 认证通过后 前端赋值认证通过
              data.formData.frThirdCheck = 1;
              // 手机验证码重置
              data.formData.legalPhoneCode = '';
              ElMessage.success("法人意愿认证已通过,有效期为1天")
            }
          })
        }

        // 撤销并重新认证
        const redoAuth = () => {
          data.formData.frThirdCheck = 0;
          // 重置三要素表单
          data.formData.legalName = '';
          data.formData.legalMobile = '';
          data.formData.legalIdentityNo = '';
          // 短信验证码重置
          data.formData.legalPhoneCode = '';
          // 重置法人三要素提交认证按钮显示标识
          data.legalAuthShowFlag = false;
        };

        return {
            useAxios,
            data,
            seeInfo,
            ...toRefs(data),
            pictureFormRules,
            pictureFormRef,
            submitForm,
            applyChange,
            applyChange2,
            frChange,
            frChange2,
            authChange,
            authChange2,
            getPhoneCode,
            submitLegalAuth,
            pictureFormSubmit,
            getPictureCode,
            resetPictureForm,
            sendSms,
            saveForm,
            redoAuth
        }
    }
}
</script>

<style lang="less" scoped>
.updateBasicInfo{
    padding: 0 250px;
    .topCol{
        width: 100%;
        overflow: hidden;
    }
    .formCont{
        padding: 30px 130px;
        .step{
            color: green;
            font-weight: bold;
            font-size: 16px;
            line-height: 36px;
            text-align: left;
            & > p{
                float: left;
            }
            .arr{
                display: block;
                float: left;
                font-size: 40px;
                margin: 0px 20px 0px 20px;
                line-height: 25px;
            }
        }
        .uploadImg{
            /deep/ .el-form-item__content{
                display: flex;
            }
        }
        .tips{
            font-size: 12px;
            color: #A3A4A8;
            line-height: 20px;
            padding: 15px 0;
            & > span a{
                color: #0078FF;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        // 上传相关
        /deep/.sign-uploader .el-upload {
             width: 150px;
             height: 150px;
             line-height: 150px;
             text-align: center;
             background-color: #fbfdff;
             border: 1px dashed #c0ccda;
             border-radius: 6px;
             box-sizing: border-box;
             cursor: pointer;
             position: relative;
             overflow: hidden;
             .el-upload-list__item-actions{
                 position: absolute;
                 width: 100%;
                 height: 100%;
                 left: 0;
                 top: 0;
                 cursor: default;
                 text-align: center;
                 color: #fff;
                 opacity: 0;
                 font-size: 20px;
                 background-color: rgba(0,0,0,.5);
                 transition: opacity .3s;
                 .el-upload-list__item-preview{
                     position: static;
                     font-size: 20px;
                 }
                 .el-upload-list__item-delete {
                     position: static;
                     font-size: 20px;
                     color: inherit;
                     margin-left: 15px;
                     display: inline-block;
                     .el-icon-delete{
                         font-family: element-icons!important;
                         speak: none;
                         font-style: normal;
                         font-weight: 400;
                         font-variant: normal;
                         text-transform: none;
                         line-height: 1;
                         vertical-align: baseline;
                         display: inline-block;
                         -webkit-font-smoothing: antialiased;
                     }
                 }
             }
             .el-upload-list__item-actions:hover{
                 opacity: 1;
             }
             .el-upload-list__item {
                 transition: all .5s cubic-bezier(.55,0,.1,1);
                 font-size: 14px;
                 color: #606266;
                 line-height: 1.8;
                 margin-top: 5px;
                 position: relative;
                 box-sizing: border-box;
                 border-radius: 4px;
                 width: 100%;
             }
         }
         /deep/.sign-uploader .el-upload:hover {
             border-color: #409EFF;
         }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
        }
        .avatar {
            width: 150px;
            height: 150px;
            display: block;
        }

        // 预览
        .sign-uploader{
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
            background-color: #fbfdff;
            border: 1px dashed #c0ccda;
            border-radius: 6px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
    }
}
</style>
