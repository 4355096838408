<template>
  <div class="login">
    <LoginTem :showSkip="false">
      <el-tabs v-model="activeName" stretch>
        <el-tab-pane label="密码登录" name="password">
          <div class="group-box">
            <input v-model="passwordLogin.account" placeholder="邮箱" />
          </div>
          <div class="group-box">
            <input v-model="passwordLogin.password" placeholder="密码" type="password"/>
          </div>
          <div class="group-box verify">
            <input v-model="passwordLogin.pictureVerify" placeholder="验证码" />
            <img :src="passwordLogin.verifyImage" @click="getPic"/>
          </div>
          <div class="record">
            <el-checkbox v-model="passwordLogin.checked">记录登录状态</el-checkbox>
            <!-- <span class="forget fg">忘记密码</span> -->
          </div>
          <el-button type="primary" class="margin-30"  @click="login"> 登录 </el-button>
          <p class="to-register">
            没有账号，<span @click="toRegister">马上注册</span>
          </p>
        </el-tab-pane>

        <el-tab-pane label="验证码登录" name="verify">
          <div class="group-box">
            <input v-model="verifyLogin.account" placeholder="邮箱" />
          </div>
          <div class="group-box verify">
            <input v-model="verifyLogin.verify" placeholder="验证码" />
            <el-button type="info" @click="getVerify" :disabled="verifyLogin.verifyDisabled">{{ getVercode }}</el-button>
          </div>
          <el-button type="primary" class="margin-112" @click="login"> 登录 </el-button>
          <p class="to-register">
            没有账号，<span @click="toRegister">马上注册</span>
          </p>
        </el-tab-pane>
      </el-tabs>
    </LoginTem>
  </div>
</template>

<script>
import LoginTem from "@/components/login-template.vue";
import { reactive, ref, watch, onMounted,inject } from "vue";
import { useRouter } from "vue-router";
import { ElMessage  } from "element-plus";
import { useStore } from 'vuex';
export default {
  name: "Login",
  components: {
    LoginTem,
  },
  setup() {
    const useAxios = inject("useAxios");
    const activeName = ref("password");
    const getVercode = ref("获取验证码");
    const router = useRouter();
    const passwordLogin = reactive({
      account: "",
      password: "",
      pictureVerify: "",
      verifyImage:'',
      checked:false
    });
    const verifyLogin = reactive({
      account: "",
      verify: "",
      verifyDisabled:false,
    })
    onMounted(()=>{
      if(localStorage.getItem('account') && localStorage.getItem('password')){
        passwordLogin.account = localStorage.getItem('account');
        passwordLogin.password = localStorage.getItem('password');
      };
      getPic();
    });
    const store = useStore();

    function login() {
      if(!verify){
        return;
      };
      let obj;
      if(activeName.value == 'password'){
        obj = {
          account:passwordLogin.account,
          loginType: 1,
          password: passwordLogin.password,
          picCode: passwordLogin.pictureVerify,
        };
      }else if(activeName.value == 'verify'){
        obj = {
          account: verifyLogin.account,
          code: verifyLogin.verify,
          loginType: 2,
        };
      };
      useAxios.post("/foreground/web/login", obj).then((res) => {
        sessionStorage.setItem('account',obj.account);
          if (res.data.code == 0) {
            if(passwordLogin.checked){
              localStorage.setItem('account',passwordLogin.account);
              localStorage.setItem('password',passwordLogin.password);
            };


            store.commit('updateToken');
            sessionStorage.setItem('token',res.data.attach.token);
            sessionStorage.setItem('userInfo',JSON.stringify(res.data.attach.accountRespList));
            ElMessage.success('登录成功');
            setTimeout(() => {
              router.replace({
                path:'/home'
              });
            }, 1500);
          }else{
            if(activeName.value == 'password'){
              getPic();
            };
          }
        })
        .catch((err) => {});
    };

    //去注册
    function toRegister() {
      router.push({
        path:'/register'
      });
    };
    // 图片验证码
    function getPic() {
      useAxios.get("/foreground/web/image/code").then((res) => {
          if(res.data.code == 0){
            passwordLogin.verifyImage = res.data.attach.picBase64;
          };
        })
        .catch((err) => {});
    };
    //校验
    function verify (){
      const rePass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
      if(activeName.value == 'password'){
        if(passwordLogin.account == ''){
          ElMessage.error('请填写邮箱');
          return false;
        }else if(!rePass.test(passwordLogin.account)){
          ElMessage.error('邮箱格式有误，请重新填写');
          return false;
        }else if(passwordLogin.password == ''){
          ElMessage.error('请填写密码');
          return false;
        }else if(passwordLogin.pictureVerify == ''){
          ElMessage.error('请填写图片验证码');
          return false;
        };
        return true;
      }else if(activeName.value == 'verify'){
        if(verifyLogin.account == ''){
          ElMessage.error('请填写邮箱');
          return false;
        }else if(!rePass.test(verifyLogin.account)){
          ElMessage.error('邮箱格式有误，请重新填写');
          return false;
        }else if(verifyLogin.verify == ''){
          ElMessage.error('请填写验证码');
          return false;
        };
        return true;
      };
    };
    // 获取验证码
    function getVerify() {
      const rePass = new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');
      const isPass = rePass.test(verifyLogin.account);
      if(verifyLogin.account == ''){
        ElMessage.error('请填写邮箱');
        return;
      }else if(!isPass){
        ElMessage.error('邮箱格式有误，请重新填写');
        return;
      };
      verifyLogin.verifyDisabled = true;
      useAxios.post("/foreground/web/email/code", {
          email: verifyLogin.account,
          type: 1,
        }).then((res) => {
          if (res.data.code == 0) {
            getVercode.value = 60;
            let timing = setInterval(() => {
              getVercode.value --;
              if(getVercode.value == 0){
                verifyLogin.verifyDisabled = false;
                getVercode.value = '获取验证码'
                clearInterval(timing);
              }
            }, 1000);
          }else{
            verifyLogin.verifyDisabled = false;
          }
        })
        .catch((err) => {});
    }
    return {
      activeName,
      passwordLogin,
      getVercode,
      login,
      getPic,
      toRegister,
      verifyLogin,
      getVerify
    };
  },
};
</script>

<style scoped lang="less">
.group-box {
  background: url(../assets/img/cc.png) no-repeat bottom;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  input {
    width: 95%;
    line-height: 50px;
    height: 50px;
    color: #000;
    background: transparent;
    font-size: 15px;
    padding-left: 5%;
    border: none;
    border-radius: 4px;
    border: 0;
    outline: none;
  }
}
.record {
  text-align: left;
  padding-left: 20px;
  padding-right: 15px;
}
.forget {
  color: #ccc;
  font-size: 12px;
}
.el-button {
  padding: 12px 60px;
}
.to-register {
  margin-top: 50px;
  color: #333;
  font-size: 15px;
  span {
    color: #017afd;
    cursor: pointer;
  }
}
.verify {
  display: flex;
  img {
    width: 130px;
    height: 45px;
    margin-left: 20px;
    cursor: pointer;
  }
  .el-button {
    padding: 2px 20px;
    background-color: #000;
    border-color: #000;
    height: 40px;
  }
}
.margin-30 {
  margin-top: 30px;
}
.margin-112 {
  margin-top: 112px;
}
</style>
