<template>
	<div id="main">
		 <!--
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link>
		-->

		<Header v-if="headerShow"></Header>
		<div :class="headerShow?'pageContent' :''">
			<router-view />
		</div>
		<Normanfoot v-if="headerShow"></Normanfoot>
	</div>
</template>

<script>
import { provide, reactive, toRefs, onMounted, watch } from 'vue'
import axios from 'axios';
import { useRoute, useRouter } from "vue-router";
import Header from './components/header.vue'
import Normanfoot from './components/footer.vue'

export default {
	name: 'app',
	components: {
		Header,
		Normanfoot
	},
	setup(props) {
		provide('useAxios', axios);

		const data = reactive({
			headerShow: true,
			// route: useRoute(),
			// router: useRouter()
		});

		const route = useRoute();
		watch(()=>route.path, ()=>{
			// console.log(route.path)
			if(route.path == '/login' || route.path == '/register' || route.path == '/updatePass'){
				data.headerShow = false;
			}else{
				data.headerShow = true;
			}
		});

		onMounted(()=>{

		});

		return {
			...toRefs(data)
		}
	}
}
</script>

<style lang="less">
@import './assets/css/index.css';

</style>
