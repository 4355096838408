<template>
    <div class="signManage">
        <div class="topCol">
            <arrowComp streamer="模板管理"></arrowComp>
        </div>


        <div class="templateList">
            <div class="noData" v-if="templateList.length == 0">暂无数据</div>
            <ul class="lists clearfix" v-else>
                <li class="itemLi" v-for="(item,index) in templateList">
                    <div class="title">{{item.templateName}}</div>
                    <div class="signImg">
                        <el-image class="signIcon" :src="require('@/assets/img/southeast.jpg')" alt="logo" fit="contain"></el-image>
                    </div>
                    <div class="signCode">
                        模板编码：
                        <input v-if="item.templateCode" :value="item.templateCode" readonly="readonly" style="width: 40%; background:#fff; border:none;">
                        <span v-if="!item.templateCode">暂无模板编码</span>
                        <span v-if="item.templateCode" class="copy" @click="copyCode(item.templateCode)">点击复制</span>
                    </div>
                    <div class="btnGroup">
                        <el-button type="text" @click="seeSign(item.ossUrl)">查看</el-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue'
import arrowComp from '../components/banner'
import { useRouter } from 'vue-router'
import { ElMessage  } from "element-plus";

export default {
    name: "TemplateManage",
    components: {
        arrowComp
    },
    setup(){
        const router = useRouter();
        const useAxios = inject("useAxios");

        const data = reactive({
            templateList: []
        })

        onMounted(()=>{
            getData();
        })

        // 列表
        const getData = () => {
            useAxios.post("/foreground/web/companySignTool/companySignTemplateList").then((res) => {
                if(res.data.code == "0"){
                    data.templateList = res.data.data;
                }
            })
            .catch((err) => {});
        }
        // 跳转
        const gotoAddSign = ()=>{
            router.push('addSign');
        }


         // 查看
        const seeSign = (url) => {
            window.open(url,"blank");
            // window.location.href = url;
        }

        // 复制
        const copyCode = (copytext) => {

            const text = document.createElement('textarea'); // 创建节点
            text.setAttribute('readonly', 'readonly');
            text.value = copytext; // 赋值
            document.body.appendChild(text); // 插入节点
            text.setSelectionRange(0, text.value.length);
            text.select(); // 选中节点
            document.execCommand('copy'); // 执行浏览器复制方法
            if (document.body.removeChild(text)) {
                ElMessage.success('复制成功');
            } else {
                ElMessage.error('复制失败');
            }
        };

        return {
            ...toRefs(data),
            getData,
            gotoAddSign,
            seeSign,
            copyCode
        }
    }
}
</script>

<style lang="less" scoped>
.signManage{
    padding: 0 250px;
    .topCol{
        width: 100%;
        overflow: hidden;
    }
    .funcBtn{
        padding: 10px 0 15px 0;
        .btn1, .btn2{
            float: right;
        }
        .btn2{
            margin-left: 20px;
        }
    }
    .templateList{
        width: 100%;
        .lists{
            .itemLi{
                width: 33.3%;
                float: left;
                margin-top: 15px;
                .title{
                    line-height: 50px;
                    font-size: 16px;
                    color: #838383;
                }
                .signImg{
                    width: 218px;
                    height: 218px;
                    margin: auto;
                    overflow: hidden;
                    border: 1px dashed #c3c3c3;
                    position: relative;
                    .signIcon{
                        width: 100%;
                        height: 100%;
                    }
                    .badge{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .default{
                    font-size: 14px;
                    margin-top: 7px;
                    color: red;
                    height: 20px;
                    line-height: 20px;
                }
                .signCode{
                    font-size: 14px;
                    color: #707478;
                    margin-top: 5px;
                    .copy{
                        color: #409EFF;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                }
                .btnGroup{
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
