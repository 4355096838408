import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import store from '../../store/index'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL; // 配置axios请求的地址process.env
axios.defaults.withCredentials = true;  //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
axios.defaults.headers.common['web-token'] = store.state.userToken;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.timeout = 300000;

let cookie = sessionStorage.getItem('sessionId');
if (cookie) {
	axios.defaults.headers.common['session-id'] = cookie;
}

// loading效果
let loading;
let startLoading = () => {
	let options = {
		lock: true,
		text: "加载中...",
	};
	loading = ElLoading.service(options);
};

let closeLoading = () => {
	loading.close();
}


// request拦截
axios.interceptors.request.use(config => {
	// 设置token
	if (!store.state.userToken) {
		config.headers.common['web-token'] = sessionStorage.getItem('token');
	};
	if (sessionStorage.getItem('sessionId')) {
		config.headers.common['session-id'] = sessionStorage.getItem('sessionId');
	}
	startLoading();

	return config;
}, error => {
	return Promise.reject(error);
})


// response拦截
axios.interceptors.response.use(response => {
	closeLoading();
	// 请求异常提示
	if (response.data.code != "0") {
		if(response.data.code == "1315"){
			window.location.replace('/#/updatePass');
			ElMessage.success("登录成功！")
			return true;
		}
		if (response.data.message) {
			ElMessage.error(response.data.message);
			return ;
		}
		if (response.data.msg) {
			ElMessage.error(response.data.msg);
			return ;
		}
	}
	// 缓存sessionid
	if (response.headers['session-id']) {
		sessionStorage.setItem('sessionId', response.headers['session-id']);
	}
	return response;
}, err => {
	ElMessage.error(err.response.data.message);
	// 登录超时
	if (err.response.status == 403) {
		window.location.replace('/#/login');
		window.sessionStorage.clear();
	}

	closeLoading();
	return Promise.reject(err);
})
