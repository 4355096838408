<template>
    <div class="signManage">
        <div class="topCol">
            <arrowComp streamer="签章管理"></arrowComp>
        </div>

        <div class="funcBtn clearfix">
            <el-button type="danger" class="btn2" plain size="medium" @click="gotoAddSign">上传印章</el-button>
            <el-button type="primary" class="btn1" plain size="medium" @click="downloadSignModel">下载印章盖章模板</el-button>
        </div>

        <div class="signList">
            <div class="noData" v-if="signList.length == 0">暂无数据</div>
            <ul class="lists clearfix" v-else>
                <li class="itemLi" v-for="(item,index) in signList">
                    <div class="title">{{item.userName}}</div>
                    <div class="signImg">
                        <el-image class="signIcon" :src="item.sealUrl" alt="logo" fit="contain"></el-image>
                        <img v-if="item.confirmStatus == 2" class="badge" src="../assets/img/sign_pass.png" alt="" width="100">
                        <img v-if="item.confirmStatus == 3" class="badge" src="../assets/img/sign_fail.png" alt="" width="100">
                        <img v-if="item.confirmStatus == 1" class="badge" src="../assets/img/sign_progress.png" alt="" width="100">
                    </div>
                    <p class="default">{{item.defaultStatus == 1 ? '默认签章' : ''}}</p>
                    <div class="signCode">
                        印章编码：
                        <input v-if="item.sealCode" :value="item.sealCode" :class="'sealCodeVal'+index" readonly="readonly" style="width: 40%; background:#fff; border:none;">
                        <span v-if="!item.sealCode">暂无印章编码</span>
                        <span v-if="item.sealCode" class="copy" @click="copyCode('sealCodeVal'+index)">点击复制</span>
                    </div>
                    <div class="btnGroup">
                        <el-button type="text" style="margin-left: 10px;" @click="deleteSign(item.sealId)">删除</el-button>
                        <el-button type="text" @click="editSign(item)">编辑</el-button>
                        <el-button type="text" @click="seeSign(item)">查看</el-button>
                        <el-button type="text" v-if="item.defaultStatus == 0" @click="setDefault(item.sealId)">设为默认</el-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue'
import arrowComp from '../components/banner'
import { useRouter } from 'vue-router'
import { ElMessage  } from "element-plus";

export default {
    name: "SignManage",
    components: {
        arrowComp
    },
    setup(){
        const router = useRouter();
        const useAxios = inject("useAxios");

        const data = reactive({
            signList: []
        })

        onMounted(()=>{
            getData();
        })

        // 列表
        const getData = () => {
            useAxios.get("/foreground/web/company/seal/list").then((res) => {
                if(res.data.code == "0"){
                    data.signList = res.data.attach;
                }
            })
            .catch((err) => {});
        }
        // 跳转
        const gotoAddSign = ()=>{
            router.push('addSign');
        }
        // 删除
        const deleteSign = (id) => {
            useAxios.post("/foreground/web/company/seal/del", {sealId: id}).then((res) => {
                if(res.data.code == "0"){
                    ElMessage.success('删除成功!');
                    getData();
                }
            })
            .catch((err) => {});
        }
         // 编辑
        const editSign = (item) => {
            router.push({
                path: `editSign/edit`,
                query: item
            });
        }
         // 查看
        const seeSign = (item) => {
            router.push({
                path: `editSign/view`,
                query: item
            });
        }
         // 设置默认
        const setDefault = (id) => {
            useAxios.post("/foreground/web/company/seal/setDefault", {sealId: id}).then((res) => {
                if(res.data.code == "0"){
                    ElMessage.success('设置默认成功!');
                    getData();
                }
            })
            .catch((err) => {});
        }
        // 复制
        const copyCode = (ref) => {
            document.getElementsByClassName(ref)[0].select();
            document.execCommand("copy");
            ElMessage.success('复制成功');
        };

        // 下载模板
        const downloadSignModel = () => {
            window.location.href = `${process.env.VUE_APP_BASE_URL}/tpl/印章盖章模板.docx`;
        }

        return {
            ...toRefs(data),
            getData,
            gotoAddSign,
            downloadSignModel,
            deleteSign,
            editSign,
            seeSign,
            setDefault,
            copyCode
        }
    }
}
</script>

<style lang="less" scoped>
.signManage{
    padding: 0 250px;
    .topCol{
        width: 100%;
        overflow: hidden;
    }
    .funcBtn{
        padding: 10px 0 15px 0;
        .btn1, .btn2{
            float: right;
        }
        .btn2{
            margin-left: 20px;
        }
    }
    .signList{
        width: 100%;
        .lists{
            .itemLi{
                width: 33.3%;
                float: left;
                margin-top: 15px;
                .title{
                    line-height: 50px;
                    font-size: 16px;
                    color: #838383;
                }
                .signImg{
                    width: 218px;
                    height: 218px;
                    margin: auto;
                    overflow: hidden;
                    border: 1px dashed #c3c3c3;
                    position: relative;
                    .signIcon{
                        width: 100%;
                        height: 100%;
                    }
                    .badge{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .default{
                    font-size: 14px;
                    margin-top: 7px;
                    color: red;
                    height: 20px;
                    line-height: 20px;
                }
                .signCode{
                    font-size: 14px;
                    color: #707478;
                    margin-top: 5px;
                    .copy{
                        color: #409EFF;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                }
                .btnGroup{
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
