<template>
    <div class="secretKeyDesensitize">
        <div class="topCol">
            <arrowComp streamer="通讯秘钥"></arrowComp>
        </div>
        <div class="mation_content">
            <div class="topCol">
                <el-row :gutter="20">
                    <el-col :span="8"></el-col>
                    <el-col :span="2" class="defalutFrontClass">秘钥</el-col>
                    <el-col :span="4">
                        <el-input v-model="authDetail.secretKeyDesensitize" :readonly="true"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="info" @click="copyCode(authDetail.secretKey)" round>复制</el-button>
                    </el-col>
                </el-row>
                <br/><br/>
                <el-row :gutter="20">
                    <el-col :span="8"></el-col>
                    <el-col :span="2" class="defalutFrontClass">向量</el-col>
                    <el-col :span="4">
                        <el-input v-model="authDetail.secretIvDesensitize" :readonly="true"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="info" @click="copyCode(authDetail.secretIv)" round>复制</el-button>
                    </el-col>
                </el-row>
              <br/><br/>
              <p class="warningText">温馨提示:秘钥及向量为与诺曼接口交互的必要参数，请妥善保管，禁止泄露给他人</p>
            </div>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {inject, onMounted, reactive} from "vue";
import arrowComp from "../components/banner";

export default {
        name: "SecretKeyAndIv",
        components: {
            arrowComp
        },
        setup() {
            const useAxios = inject("useAxios");
            const authDetail = reactive({
                streamerMsg: "秘钥向量",
                secretKey: '',
                secretIv: '',
                // 脱敏
                secretKeyDesensitize: '',
                secretIvDesensitize: ''
            });

            onMounted(() => {
                getAuthDetail();
            });

            // 复制
            const copyCode = (copytext) => {
                const text = document.createElement('textarea'); // 创建节点
                text.setAttribute('readonly', 'readonly');
                text.value = copytext; // 赋值
                document.body.appendChild(text); // 插入节点
                text.setSelectionRange(0, text.value.length);
                text.select(); // 选中节点
                document.execCommand('copy'); // 执行浏览器复制方法
                if (document.body.removeChild(text)) {
                    ElMessage.success('复制成功');
                } else {
                    ElMessage.error('复制失败');
                }
            };

            //企业详情
            function getAuthDetail() {
                useAxios.get("/foreground/web/company/auth/detail").then((res) => {
                    if (res.data.code == "0") {
                        authDetail.secretKey = res.data.attach.secretKey;
                        authDetail.secretIv = res.data.attach.secretIv;
                        authDetail.secretKeyDesensitize = desensitize(authDetail.secretKey);
                        authDetail.secretIvDesensitize = desensitize(authDetail.secretIv);
                    }
                });
            }

            // 脱敏
            function desensitize(str) {
                let len = str.length;

                if (len > 12) {
                    let start = str.slice(0, 6);
                    let end = str.slice(-6);
                    let middle = "*".repeat(len - 12);
                    return start + middle + end;
                } else {
                    return str;
                }
            }

            return {
                authDetail,
                copyCode
            };
        }
    };


</script>

<style lang="less" scoped>
    .secretKeyDesensitize {
        padding: 0 250px;

        .topCol {
            width: 100%;
            overflow: hidden;
        }

        .addCol {
            width: 100%;
            height: 300px;
            padding-top: 40px;
            box-sizing: border-box;

            .uploadImg {
                width: 200px;
                height: 200px;
                line-height: 200px;
                float: left;
                background-color: #fbfdff;
                border: 1px dashed #c0ccda;
                border-radius: 6px;
                box-sizing: border-box;
                margin-left: 30px;

                .avatar {
                    width: 200px;
                    height: 200px;
                    display: block;
                }
            }

            .uploadInfo {
                width: 35%;
                float: left;
                padding-left: 50px;

                .info {
                    display: flex;
                    height: 40px;
                    line-height: 40px;
                    margin-bottom: 24px;

                    label {
                        width: 120px;
                    }
                }

                .uploadBtn {
                    text-align: left;
                    padding-top: 10px;
                }
            }
        }

        .btn {
            .el-button {
                width: 24%;
            }
        }

        .tips {
            p {
                width: 72%;
                position: relative;
                margin: auto;
                padding: 10px;
                margin-top: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #707478;
                text-align: left;
                border: 1px solid #c3c3c3;
                border-radius: 6px;
            }
        }

        .warningText{
          color: #ff0000;
          font-size: 20px;
          margin-left: 10px;
        }

    }
</style>
