<template>
  <div>
    <div class="wd_bg">{{streamer}}</div>
  </div>
</template>

<script>
export default {
  name:'banner',
  props:{
    streamer:String
  }
}
</script>

<style lang="less" scoped>
.wd_bg {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding-left: 65px;
  margin-top: 10px;
  background: url(../assets/img/img1.png) 0px top no-repeat;
  margin-top: 30px;
  text-align: left;
  box-sizing: border-box;
}
</style>