<template>
    <div class="home">
        <div class="logo">
            <el-image :src="require('../assets/img/home_logo.png')" alt="logo" fit="contain"></el-image>
        </div>

        <div class="mainContent">
            <el-card class="box-card" shadow="hover">
                <div class="titleInfo">
                    <el-image :src="require('../assets/img/tb_1.png')" alt="logo" fit="contain"></el-image>
                    <p>账户信息</p>
                </div>
                <div class="userInfo">
                    <div class="item">
                        <label>账号：</label><span>{{infoList.accountNo}}</span>
                    </div>
                    <div class="item">
                        <label>注册时间：</label><span>{{infoList.registerTime}}</span>
                    </div>
                    <div class="item">
                        <label>认证状态：</label><span>{{infoList.authStatus == 0 ? '未认证' : (infoList.authStatus == 2 ? '审核中' : '已认证')}}</span>
                        <router-link class="blue" to="/userCenter" v-if="infoList.authStatus == 0" @click="goAuth">去认证 >></router-link>
                        <router-link class="blue" :to="'/userCenter/updateBasicInfo/'+ userId" v-if="infoList.authStatus == 1" @click="goUpdate">更新 >></router-link>
                    </div>
                    <div class="item">
                        <label>账号类型：</label><span>{{infoList.userType == 1 ? '个人' : '企业'}}</span>
                    </div>
                    <div class="item" v-if="infoList.name">
                        <label>名称：</label><span>{{infoList.name}}</span>
                    </div>
                </div>
            </el-card>
             <el-card class="box-card" shadow="hover">
                <div class="titleInfo">
                    <el-image :src="require('../assets/img/tb_2.png')" alt="logo" fit="contain"></el-image>
                    <p>印章信息</p>
                </div>
                <div class="signInfo">
                    <el-image v-if="infoList.sealUrl" :src="infoList.sealUrl" alt="sign" fit="contain"></el-image>
                </div>
            </el-card>
             <el-card class="box-card" shadow="hover">
                <div class="titleInfo">
                    <el-image :src="require('../assets/img/tb_3.png')" alt="logo" fit="contain"></el-image>
                    <p>签发量月度报表</p>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { inject, onMounted, reactive, toRefs } from "vue";
import { useStore } from 'vuex';


export default {
    name: "Home",
    setup() {
        const useAxios = inject("useAxios");
        const store = useStore();
        const data = reactive({
            userId: sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo'))[0].userId : '',
            infoList: {}
        });

        onMounted(()=>{
            initStatus();
            getInfo();
        });

        const getInfo = () => {
            useAxios.get("/foreground/web/company/account/detail?userId=" + data.userId).then((res) => {
                if(res.data.code == "0"){
                    data.infoList = res.data.attach[0];
                    sessionStorage.setItem('authStatus', res.data.attach[0].authStatus);
                }
            })
            .catch((err) => {});
        };

        const goAuth = () => {
            sessionStorage.setItem('mainTabsStatus', 'second');
            sessionStorage.setItem('userTabsStatus', 0);
            store.dispatch('goChangeUserTabs');
        };
        const goUpdate = () => {
            sessionStorage.setItem('mainTabsStatus', 'second');
            sessionStorage.setItem('userTabsStatus', 0);
            store.dispatch('goChangeUserTabs');
        };

        const initStatus =() => {
            sessionStorage.setItem('userTabsStatus', 0);
            store.dispatch('goChangeUserTabs');
        };

        return {
            ...toRefs(data),
            getInfo,
            goAuth,
            goUpdate
        };
    },
};
</script>

<style lang="less" scoped>
.home{
    width: 100%;
    .logo{
        width: 80%;
        margin: 0 auto;
        padding-top: 50px;
        text-align: center;
    }
    .mainContent{
        display: flex;
        padding: 40px 150px;
        .box-card{
            width: 33.3%;
            height: 420px;
            padding: 10px;
            /deep/ .el-card{
                height: 100%;
                border: none;
                background-color: unset;
            }
            /deep/ .el-card:hover{
                // border: 1px solid #EBEEF5;
                background-color: #FFF;
                box-shadow: 8px 8px 14px #cccccc;
            }
            .titleInfo{
                padding-top: 50px;
                & > p{
                    font-weight: bold;
                    font-size: 16px;
                    padding-bottom: 15px;
                }
            }
            .userInfo{
                width: 100%;
                margin: 30px auto 0;
                text-align: left;
                font-size: 15px;
                font-weight: 500;
                line-height: 26px;
                .item{
                    padding-left: 5%;
                    & > label{
                        display: inline-block;
                        width: 80px;
                        margin-left: 15px;
                    }
                    & > span{
                        margin-left: 15px;
                    }
                    .blue{
                        color: #127AFE;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
            .signInfo{
                margin-top: 20px;
                .el-image{
                    width: 160px;
                }
            }
        }
    }
}
</style>
