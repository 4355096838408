<template>
    <div class="login">
        <LoginTem :showSkip="false">
            <el-tabs v-model="activeName" stretch>
                <el-tab-pane label="密码升级" name="verify">
                    <div style="text-align: left;">
                        <span style="color: red;font-size: small;" >为提升用户的账户安全，登录用户请升级您的账户密码，验证码会发送到您注册邮箱当中，请注意查收！谢谢配合！</span>
                        <br/><br/>
                    </div>
                    <div class="group-box verify">
                        <input v-model="verifyLogin.verify" placeholder="验证码" maxlength="6"/>
                        <el-button type="info" @click="getVerify" :disabled="verifyLogin.verifyDisabled">{{ getVercode }}</el-button>
                    </div>
                    <div class="group-box">
                        <input v-model="verifyLogin.password" placeholder="密码" type="password" maxlength="20"/>
<!--                        <div style="text-align: left;">-->
                            <div style="color: red;font-size: x-small;" >密码必须包含字母(大小写)及数字，长度12~20个字符</div>

                    </div>
                    <div class="group-box">
                        <input v-model="verifyLogin.repassword" placeholder="确认密码" type="password" maxlength="20"/>

                    </div>
                    <div class="group-box">
                        <input v-model="verifyLogin.account"  type="hidden" maxlength="50"/>
                    </div>
                    <el-button type="primary" class="margin-112" @click="submitPassword"> 提交 </el-button>
                </el-tab-pane>
            </el-tabs>
        </LoginTem>
    </div>
</template>

<script>
    import LoginTem from "@/components/login-template.vue";
    import { ElMessage  } from "element-plus";
    import {  reactive, ref, onMounted, inject } from 'vue';
    import { useRouter, } from 'vue-router';
    import { useStore } from 'vuex';
    export default {
        name: "UpdatePass",
        components: {
            LoginTem,
        },
        setup(){
            const useAxios = inject("useAxios");
            const data = reactive({
                showMsg:"密码升级",
            });
            const activeName = ref("verify");
            const getVercode = ref("发送验证码到邮箱");
            const router = useRouter();
            const store = useStore();
            const verifyLogin = reactive({
                account: "",
                verify: "",
                password: "",
                repassword: "",
                verifyDisabled:false,
            });
            onMounted(()=>{
                if(sessionStorage.getItem('account') ){
                    verifyLogin.account = sessionStorage.getItem('account');
                };
            });

            // 获取验证码
            function getVerify() {
                verifyLogin.verifyDisabled = true;
                useAxios.post("/foreground/web/email/code", {
                    email: verifyLogin.account,
                    type: 3,
                }).then((res) => {
                    if (res.data.code == 0) {
                        getVercode.value = 60;
                        let timing = setInterval(() => {
                            getVercode.value --;
                            if(getVercode.value == 0){
                                verifyLogin.verifyDisabled = false;
                                getVercode.value = '获取验证码'
                                clearInterval(timing);
                            }
                        }, 1000);
                    }else{
                        verifyLogin.verifyDisabled = false;
                    }
                })
                    .catch((err) => {});
            };
            function submitPassword() {
                if(!verify()){
                    return;
                };
                let  obj = {
                    email:verifyLogin.account,
                    newPassword: verifyLogin.password,
                    repeatNewPassword: verifyLogin.repassword,
                    verificationCode: verifyLogin.verify,
                    };

                useAxios.post("/foreground/web/company/password/reset", obj).then((res) => {
                    if (res.data.code == 0) {
                        ElMessage.success('密码升级成功');
                        setTimeout(() => {
                            router.replace({
                                path:'/login'
                            });
                        }, 1500);
                    }else{
                        ElMessage.error('密码升级失败：',res.data.message);
                    }
                })
                    .catch((err) => {});
            };
            //校验
            function verify (){
                const rePass = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{12,20}$', '');
                if(verifyLogin.verify == ''){
                    ElMessage.error('请填写邮件验证码');
                    return false;
                }
                else if(verifyLogin.passwordpassword == ''){
                    ElMessage.error('请填写密码！');
                    return false;
                }else if(!rePass.test(verifyLogin.password)){
                    ElMessage.error('密码格式有误，请重新填写！');
                    return false;
                }else if(verifyLogin.repassword == ''){
                    ElMessage.error('请填写确认密码！');
                    return false;
                }else if(verifyLogin.repassword != verifyLogin.repassword){
                    ElMessage.error('密码与确认密码不相同！');
                    return false;
                };
                return true;

            };
            return {
                activeName,
                verifyLogin,
                getVerify,
                getVercode,
                verify,
                submitPassword
            };
        }
    }
</script>

<style scoped lang="less">
    .group-box {
        background: url(../assets/img/cc.png) no-repeat bottom;
        margin-bottom: 5px;
        width: 100%;
        padding-left: 5px;
        box-sizing: border-box;
        input {
            width: 95%;
            line-height: 35px;
            height: 45px;
            color: #000;
            background: transparent;
            font-size: 15px;
            padding-left: 5%;
            border: none;
            border-radius: 4px;
            border: 0;
            outline: none;
        }
    }
    .el-button {
        padding: 12px 60px;
    }
    .verify {
        display: flex;
        img {
            width: 130px;
            height: 35px;
            margin-left: 20px;
            cursor: pointer;
        }
        .el-button {
            padding: 2px 10px;
            background-color: #000;
            border-color: #000;
            height: 35px;
        }
    }
    .margin-112 {
        margin-top: 10px;
    }
</style>
