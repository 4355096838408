<template>
    <div class="normanFoot">
        <div class="content">COPYRIGHT ©2021 诺曼签约版权所有. 辽ICP备17006874号-1</div>
    </div>
</template>

<script>

export default {
    name: "normanFoot",
    setup() {
        
    },
};
</script>

<style lang="less" scoped>
.normanFoot{
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    .content{
        width: 100%;
        height: 74px;
        line-height: 74px;
        font-size: 15px;
        font-weight: 500;
        background: url(../assets/img/foot_bg.jpg) 0px top no-repeat;
        // border-top: 2px solid #A1A1A1;
    }
}
</style>
